<template>

  <div class="container" style="padding: 91px 0 0;">

    <div class="row">
      <!--      <div class="col-md-8 col-md-offset-2">-->
      <div class="col-md-14">

        <div class="title-content">

          <h2 class="section-title" style="color: #2c4755">注册/试用</h2>
          <!--          <p>在此填写您茶馆的基础信息以便金米客户以合适得方式联系您.</p>-->
        </div>
        <contact-us-tabs></contact-us-tabs>
      </div>

    </div> <!-- /.row -->

    <!--    <form class="contact-form">-->

    <!--      <div class="row">-->

    <!--        <div class="col-md-4">-->

    <!--          <label class="sr-only" for="Name">Name</label>-->
    <!--          <input type="text" name="name" id="Name" placeholder="店名">-->

    <!--        </div>-->

    <!--        <div class="col-md-4">-->

    <!--          <label class="sr-only" for="Email">Email</label>-->
    <!--          <input type="email" name="email" id="Email" placeholder="联系人">-->

    <!--        </div>-->

    <!--        <div class="col-md-4">-->

    <!--          <label class="sr-only" for="Subject">Subject</label>-->
    <!--          <input type="text" name="subject" id="Subject" placeholder="手机号">-->

    <!--        </div>-->

    <!--      </div> &lt;!&ndash; /.row &ndash;&gt;-->

    <!--      <div class="row">-->

    <!--        <div class="col-md-12">-->

    <!--          <label class="sr-only" for="Message">Message</label>-->
    <!--          <textarea name="message" id="Message" rows="9" placeholder="输入您的留言"></textarea>-->

    <!--        </div>-->

    <!--      </div> &lt;!&ndash; /.row &ndash;&gt;-->

    <!--      <button type="submit" class="btn btn-default white-blue">提交 您的信息</button>-->

    <!--    </form>    &lt;!&ndash; /.contact-form &ndash;&gt;-->

  </div> <!-- /.container -->

</template>

<script>
import ContactUsTabs from "@/components/ContactUs/ContactUsTabs";

export default {
  components: {
    ContactUsTabs
  },
  name: "HomeMainContact"
}
</script>

<style scoped>

</style>
