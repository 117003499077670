import {createApp} from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
import store from './store'
import VueSmoothScroll from 'vue3-smooth-scroll'
import './fonts/font.css'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// app.config.globalProperties.$vueName = 'Vue3全局挂载名称'
// app.config.globalProperties.$http = axios

app.use(ElementPlus)
.use(VueSmoothScroll, {
    duration: 1000,
    updateHistory: false
})
.use(store)
.use(router)
.use(ElementPlus)
.mount('#app')

