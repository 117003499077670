<template>
  <router-view v-if="routerAlive"></router-view>
</template>
<script>
export default {
  data() {
    return {
      routerAlive: true
    }
  },
  methods: {
    routerRefresh() {
      this.routerAlive = false;
      this.$nextTick(() => {
        this.routerAlive = true;
      });
    }
  }
}
</script>


<style>
@import "../src/css/bootstrap.min.css";
@import "../src/css/googleapis.css";
@import "../src/css/social-icons.css";
@import "../src/css/slick.css";
@import "../src/css/style.css";
/*@import "./css/bootstrap.min.css";*/
/*@import "./css/googleapis.css";*/
/*@import "./css/social-icons.css";*/
/*@import "./css/slick.css";*/
/*@import "./css/style.css";*/

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  color: #637988;
  /*line-height: 20px;*/
  box-sizing: border-box;
}

html,
body {
  max-width: 100%;

  overflow-x: hidden;
}

*{
  -webkit-touch-callout:none;  /*系统默认菜单被禁用*/
  -webkit-user-select:none; /*webkit浏览器*/
  -khtml-user-select:none; /*早期浏览器*/
  -moz-user-select:none;/*火狐*/
  -ms-user-select:none; /*IE10*/
  user-select:none;
}
input{
  -webkit-user-select:auto; /*webkit浏览器*/
}
textarea{
  -webkit-user-select:auto; /*webkit浏览器*/
}


section {
  background: rgb(244, 242, 238) !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/*::-webkit-scrollbar {*/

/*  !*隐藏滚轮*!*/
/*  display: none !important;*/
/*}*/

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101F1C, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, .5);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color .3s;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, .3);
}


</style>
