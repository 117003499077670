<template>
  <div class="contentTop">
    <div class="content"
         v-for="(item,index) in serviceList" :key="index" :class="{'shadow-drop-2-center':isEnter === index}"
         @mouseenter="isEnter= index;" @mouseleave="isEnter = -1">
      <!--    -->
      <div class="content_card" style="width: 350px;display: flex;padding:0 15px;align-items: center;">
        <!--   图片   -->
        <div style="width: 150px;display: inline-block;padding: 10px">
          <div class="service_img"
               :style="'background:url('+require('../../assets/service/'+item.img+'')+') no-repeat center center;background-size: cover;'"></div>
        </div>
        <!--   内容   -->
        <div style="width: 150px;display: inline-block;">
          <!--    头部    -->
          <div>
            <!--    标题      -->
            <div style="font-size: 18px;font-weight: bold;padding-bottom: 10px">
              {{ item.name }}
            </div>
            <!--          &lt;!&ndash;    次标题      &ndash;&gt;-->
            <!--          <div>-->
            <!--            次标题-->
            <!--          </div>-->
            <!--    线      -->
            <div style="border-top: 3px solid #57b05b;width: 30px;padding-bottom: 10px"></div>

          </div>
          <!--    说明    -->
          <div style="font-size: 16px;color: #a0a0a0;">
            <p>{{ item.secondName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewForService",
  data() {
    return {
      screenType:false,
      isEnter: -1,
      serviceList: [
        {
          img: "服务7.jpg",
          name: "茶楼茶馆"
        },
        {
          img: "服务1.jpg",
          name: "茶店茶叶品牌专柜"
        },
        {
          img: "服务2.jpg",
          name: "茶企连锁",
          secondName: '直营店、代理商、加盟店',
        },
        {
          img: "茶会所.jpg",
          name: "茶会所 茶书院"
        },
        {
          img: "服务6.jpg",
          name: "新式茶饮",
          secondName: "现制茶 调饮茶"
        },
        {
          img: "服务5.jpg",
          name: "茶仓"
        },
        {
          img: "服务4.jpg",
          name: "茶艺培训机构 茶学院"
        },
        {
          img: "服务3.jpg",
          name: "茶会 茶旅"
        },
        {
          img: "无人茶室.jpeg",
          name: "智慧茶馆 无人茶室"
        },
      ]
    }
  },
  methods(){

  },
}
</script>

<style scoped>

.service_img {
  width: 70%;
  height: 80px;
  display: inline-block;
  background-size: cover;
  box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);
}

/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {
  .contentTop {
    text-align: center;
    width: 100%;
    margin: 0px auto;
  }

  .content {
    color: #000000;
    display: inline-block;
    margin: 0;
    text-align: left;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 768px) and (max-width: 992px) {
  .contentTop {
    text-align: center;
    width: 100%;
    margin: 0px auto;
  }

  .content {
    color: #000000;
    display: inline-block;
    margin: 0 10px;
    text-align: left;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 992px) {
  .contentTop {
    text-align: left;
    width: 1200px;
    margin: 0 auto
  }

  .content {
    color: #000000;
    display: inline-block;
    margin: 0 10px;
    text-align: left;
  }

  .shadow-drop-2-center {
    -webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes shadow-drop-2-center {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px);
      transform: translateZ(50px);
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }

  @keyframes shadow-drop-2-center {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px);
      transform: translateZ(50px);
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
}


</style>
