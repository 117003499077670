<template>
  <div class="row_div_div">
    <div class="row_div_div_div">
      <img :src="require('../../assets/QRC/QRC1.png')" class="row_div_div_div_img"
           :class="{'scale-up-tl':isEnter === 1}" @mouseenter="isEnter = 1;"
           @mouseleave="isEnter = 0">
    </div>
    <div class="row_div_div_div">
      <img :src="require('../../assets/QRC/QRC2.png')" class="row_div_div_div_img"
           :class="{'scale-up-tr':isEnter === 2}" @mouseenter="isEnter = 2;"
           @mouseleave="isEnter = 0">
    </div>
    <div class="row_div_div_div">
      <img :src="require('../../assets/QRC/QRC3.png')" class="row_div_div_div_img"
           :class="{'scale-up-bl':isEnter === 3}" @mouseenter="isEnter = 3;"
           @mouseleave="isEnter = 0">
    </div>
    <div class="row_div_div_div">
      <img :src="require('../../assets/QRC/QRC4.png')" class="row_div_div_div_img"
           :class="{'scale-up-br':isEnter === 4}" @mouseenter="isEnter = 4;"
           @mouseleave="isEnter = 0">
    </div>
  </div>
</template>

<script>
export default {
  name: "QRCode",
  data() {
    return {
      isEnter: 0
    }
  }
}
</script>

<style scoped>

.row_div {
  padding: 5px;
  text-align: center
}

.row_div_div {
  width: 100%;

}

.row_div_div_div {
  width: 43%;
  display: inline-block;
  padding: 3px;
  text-align: center;
  /*box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);*/
  /*border-radius: 8px;*/
  /*background: #ffffff*/
}

.row_div_div_div_img {
  background: #ffffff;
  width: 80px;
  display: inline-block;
  transition: all 0.5s;
  /*border-radius: 12px*/
}

.row_div_div_div_span {
  display: block;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
}

/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {
  .row_div_div_div {
    width: auto;
    display: inline-block;
    padding: 3px;
    text-align: center;
    /*box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);*/
    /*border-radius: 8px;*/
    /*background: #ffffff*/
  }
}

@media (min-width: 768px) {

  /*左上*/
  .scale-up-tl {
    -webkit-animation: scale-up-tl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-tl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  @-webkit-keyframes scale-up-tl {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
    }
    100% {
      -webkit-transform: scale(2);
      transform: scale(2);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
    }
  }

  @keyframes scale-up-tl {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
    }
    100% {
      -webkit-transform: scale(2.2);
      transform: scale(2.2);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
    }
  }

  /*左下*/
  .scale-up-bl {
    -webkit-animation: scale-up-bl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-bl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  @-webkit-keyframes scale-up-bl {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scale(2.2);
      transform: scale(2.2);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }
  }

  @keyframes scale-up-bl {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scale(2.2);
      transform: scale(2.2);
      -webkit-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
    }
  }

  /*右上*/
  .scale-up-tr {
    -webkit-animation: scale-up-tr .4s cubic-bezier(.39, .575, .565, 1.000) both;
    animation: scale-up-tr .4s cubic-bezier(.39, .575, .565, 1.000) both
  }

  @-webkit-keyframes scale-up-tr {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 100% 0;
      transform-origin: 100% 0
    }
    100% {
      -webkit-transform: scale(2.2);
      transform: scale(2.2);
      -webkit-transform-origin: 100% 0;
      transform-origin: 100% 0
    }
  }

  @keyframes scale-up-tr {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 100% 0;
      transform-origin: 100% 0
    }
    100% {
      -webkit-transform: scale(2.2);
      transform: scale(2.2);
      -webkit-transform-origin: 100% 0;
      transform-origin: 100% 0
    }
  }

  /*右下*/
  .scale-up-br {
    -webkit-animation: scale-up-br 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-br 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  @-webkit-keyframes scale-up-br {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
    }
    100% {
      -webkit-transform: scale(2.2);
      transform: scale(2.2);
      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
    }
  }

  @keyframes scale-up-br {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
    }
    100% {
      -webkit-transform: scale(2.2);
      transform: scale(2.2);
      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
    }
  }

}
</style>
