<template>
  <div class="content">
    <!--  头部  -->
    <div class="content_top">
      <div class="header">
        <div>{{ title }}</div>
      </div>
      <div class="secondTittle">
        <div>{{ second }}</div>
      </div>
      <div class="line"></div>
    </div>
    <!--  内容  -->
    <div>
      <!--   架子   -->
      <div class="Shelf" :class="{'revers':reverse}">
        <!--    图片    -->
        <div class="view" :class="{'imgRevers ShelfViewRevers':reverse}">
          <img :src="require('../../assets/CustomService/'+img)" alt="" style="width: 100%">
        </div>
        <!--    说明    -->
        <div class="detail">
          <!--     文字     -->
          <p class="text">
            {{ text }}
          </p>
          <!--     按钮     -->
          <div class="btnView" >
            <div @click="clickBtn(index)" style="cursor: pointer">
              {{ btnText }}
            </div>
          </div>
          <div style="display: inline;width: 50%;text-align: right" v-show="showDetail">
            <div @click="toUrl(url)" style="cursor:pointer;">
              详情
              <img src="../../assets/next2.png" alt="" style="width: 30px;">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    second: {
      type: String
    },
    img: {
      type: String
    },
    text: {
      type: String
    },
    btnText: {
      type: String
    },
    index: {
      type: Number,
      default: 0,
    },
    url: {
      type: String
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    showDetail:{
      type:Boolean,
      default:true,
    }
  },
  name: "NewService",
  created() {
  },
  data() {
    return {}
  },
  methods: {
    clickBtn(index) {
      this.$emit("click_Func", index);
    },
    toUrl(url) {
      // this.$router.push({path: url});
      window.open(url);
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}

.header div:first-child {
  width: 150px;
  margin: 0 auto;
  text-align: center;
  height: 40px;
  font-size: 25px
}

.line {
  width: 75px;
  margin: 10px auto;
  border-bottom: 5px solid #57b05b
}

.secondTittle {
  width: auto;
  margin: 0 auto;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  color: #535353;
}

.revers {
  flex-direction: row-reverse;
}


/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {
  .content {
    width: 100%;
    margin: 0 auto;
    color: #000000;
    padding-top: 20px
  }

  .content_top {
    margin-bottom: 20px;
  }

  .Shelf {
    width: 100%;
    margin: 0 auto;
    display: flex;
    /*align-items: center;*/
  }

  .Shelf .view {
    width: 400px;
    padding-left: 20px;
    padding-top: 20px;
  }

  .text {
    font-size: 1.6rem;
    color: #535353;
    margin-bottom: 0.2rem;
  }

  .btnView {
    text-align: center;
    padding-top: 30px
  }

  .btnView div {
    border-radius: 3px;
    width: 100px;
    line-height: calc(50px - 16px / 2);
    background: #57b05b;
    color: #fff;
    font-size: 16px;
  }

  .Shelf .detail {
    width: 600px;
    overflow: hidden;
    padding: 20px;
  }

  .ShelfViewRevers {
    width: 400px;
    padding-right: 20px;
    padding-top: 20px;
  }

  .imgRevers {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 768px) and (max-width: 992px) {
  .content {
    width: 100%;
    margin: 0 auto;
    color: #000000;
    padding-top: 20px
  }

  .content_top {
    margin-bottom: 60px;
  }

  .Shelf {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .Shelf .view {
    width: 400px;
  }

  .btnView {
    text-align: center;
    padding-top: 30px
  }

  .btnView div {
    width: 180px;
    height: 56px;
    line-height: 56px;
    background: #57b05b;
    color: #fff;
    font-size: 16px;
  }
  .text {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #535353;
    line-height: 30px;
  }

  .Shelf .detail {
    width: 600px;
    overflow: hidden;
    /*padding: 40px;*/
  }

  .imgRevers {
    margin-left: 110px !important;
    margin-right: 0 !important;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 992px) {
  .content {
    width: 1200px;
    margin: 0 auto;
    color: #000000;
    padding-top: 20px
  }

  .content_top {
    margin-bottom: 60px;
  }

  .Shelf {
    width: 80%;
    margin: 0 auto;
    display: flex;
  }

  .Shelf .view {
    width: 400px;
    margin-right: 110px;
  }

  .text {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #535353;
    line-height: 30px;
  }

  .btnView {
    display: inline-block;
    text-align: center;
    padding-top: 30px
  }

  .btnView div {
    width: 180px;
    height: 56px;
    line-height: 56px;
    background: #57b05b;
    color: #fff;
    font-size: 16px;
  }

  .Shelf .detail {
    width: 600px;
    overflow: hidden;
    /*padding: 40px;*/
  }

  .imgRevers {
    margin-left: 110px !important;
    margin-right: 0 !important;
  }
}
</style>
