<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <div class="title-content">
          <h2 class="section-title">了解茶艺人</h2>
        </div>
      </div>
    </div>
    <div class="row" style="color: black">
      <div class="company_group">
        <div style="margin: 0 auto">
          <img :src="require('../../img/mediaQRCode/公司.9a4d801f.png')" alt=""
               style="width: 200px;">
        </div>
        <div class="company_group_before" style="display: inline-block;margin-left: 20px;">
          <div>
            <div class="describe">北京金米天成科技发展有限公司成立于2009年，2011年获国家认定“双软企业”资质，是一家服务于茶行业的产业互联网公司，
              公司全部产品和平台拥有独立著作权。
            </div>
            <div class="describe">公司打造的“茶艺人”平台定位于服务全国茶楼茶馆茶店、茶企连锁、茶会所、茶书院、茶仓、茶会茶旅茶艺培训机构。
            </div>
            <div class="describe">
              金米天成以“科技兴茶”为品牌主张，以科技赋能茶产业、继承和发展茶文化为使命,秉承数据安全、技术创新、便捷好用的理念，
              致力于连接茶企-茶馆-茶艺师-茶客。提供门店数字化、人才服务、托管运营、茶馆集采等业务版块的解决方案和相关服务。
            </div>
            <div>

            </div>
          </div>
        </div>
      </div>


      <!--      <div class="company_endTitle">-->
      <!--        未来,金米天成公司将持续深耕茶行业,做好茶科技、茶产业、茶文化的“三茶平台服务-->
      <!--      </div>-->
      <!--      <div class="col-md-6" style="width: 100%;">-->
      <!--        <img :src="require('../../assets/公司简介.png')" alt="" style="width: 100%;height: auto">-->
      <!--      </div>-->
    </div> <!-- /.row -->
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 0
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  name: "HomeMainCompany",
  data() {
    return {
      url: "../../src/img/mediaQRCode/douyin.png"
    }
  },
  created() {

  }
}
</script>

<style scoped>

.video_div {
  text-indent: 2em;
  font-family: 微软雅黑, serif;
  font-size: 18px;
}

/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {
  .video_div {
    text-indent: 1em;
    font-family: 微软雅黑, serif;
    text-align: left;
    font-size: 16px;
    padding: 10px;
  }

  .company_title {
    width: 80%;
    font-size: 20px;
    margin: 0 auto;
    height: 50px;
    text-align: center
  }

  .company_group {
    width: 100%;
    margin: 0 auto;
  }

  .company_group img {
    display: block;
    margin: 0 auto;
  }

  .company_group_before {
    padding: 10px 15px;
  }

  .company_group_before_bottom {
    margin-top: 15px;
  }

  .company_endTitle {
    width: 100%;
    font-size: 14px;
    margin: 0 auto;
    text-align: center;
    vertical-align: middle;
    padding: 10px;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 768px) and (max-width: 992px) {
  .describe {
    font-size: 18px;
    text-indent: 2em;
    /*font-family: Arial,serif;*/
    /*font-weight: 800;*/
  }

  .company_title {
    width: 80%;
    font-size: 35px;
    margin: 0 auto;
    height: 100px;
    text-align: center
  }

  .company_group {
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .company_group_before_bottom {
    margin-top: 50px;
  }

  .company_endTitle {
    width: 100%;
    font-size: 24px;
    margin: 0 auto;
    height: 100px;
    text-align: center;
    vertical-align: middle;
    padding: 50px;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 992px) {
  .describe {
    font-size: 18px;
    text-indent: 2em;
    /*font-family: Arial,serif;*/
    /*font-weight: 800;*/
  }

  .company_title {
    width: 80%;
    font-size: 35px;
    margin: 0 auto;
    height: 100px;
    text-align: center
  }

  .company_group {
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .company_group_before_bottom {
    margin-top: 50px;
  }

  .company_endTitle {
    width: 100%;
    font-size: 24px;
    margin: 0 auto;
    height: 100px;
    text-align: center;
    vertical-align: middle;
    padding: 50px;
  }
}
</style>
