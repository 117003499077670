<template>
  <div class="row" v-show="isShowHeader">
    <div class="col-md-8 col-md-offset-2">
      <div class="title-content">
        <h2 class="section-title">客户照片墙</h2>
      </div>
    </div>
  </div>
  <el-row class="project-case" :style="bIsMobile?'justify-content: space-around;':''">

    <div class="content" v-if="!bIsMobile">
      <div
          class="content-list"
          ref="contentList"
          @mousedown.prevent="contentListMouseDown"
      >
        <el-col
            class="content-item"
            :span="4"
            v-for="(item, index) in dataList"
            :key="index"
            v-show="!isShowHeader">
          <img @mousedown.prevent="" :src="require('../../assets/MenTou/'+item.url)" alt=""/>
        </el-col>

        <el-col
            class="content-item"
            :span="4"
            v-for="(item, index) in dataList2"
            :key="index"
            v-show="isShowHeader">
          <img @mousedown.prevent="" :src="require('../../assets/MenTou/'+item.url)" alt=""/>
        </el-col>
      </div>
    </div>

    <div v-if="bIsMobile" style="text-align: center">

      <div v-show="isShowHeader" v-for="(item,index) in unique(dataList)" :key="index" style="display: inline-block">
        <img :src="url + item.url" alt="" style="display: inline-block;width:100px;height: 100px;margin: 10px">
      </div>


      <div v-show="!isShowHeader" v-for="(item,index) in unique(dataList2)" :key="index" style="display: inline-block">
        <img :src="url + item.url" alt="" style="display: inline-block;width:100px;height: 100px;margin: 10px">
      </div>
    </div>

  </el-row>
</template>

<script>


export default {
  name: 'HomeMainForPortfolio',
  props: {
    isShowHeader: {
      type: Boolean,
      default: false,
    },
    bIsMobile: {
      type:Boolean,
      default: false
    },
  },
  data() {
    return {
      dataList: [
        // 开头填充
        {url: "露雨轩.png"},
        {url: "同庆号普洱茶.png"},
        // {url: "雨林古树茶.png"},
        // {url: "正山堂.png"},
        // {url: "尚羽轩.png"},
        {url: "升格楼.png"},
        {url: "拾年茶馆.png"},
        {url: "水云间.png"},
        {url: "泰州会馆.png"},
        // {url: "天咨雨茶坊.png"},
        {url: "茗福居 (1).png"},
        {url: "澜沧古茶.png"},
        // {url: "mmexport1640240130141.jpg"},
        // {url: "mmexport1641373204621.jpg"},
        // {url: "mmexport1642337175909.jpg"},
        // {url: "mmexport1643215669142.jpg"},
        // {url: "mmexport1643287197915.jpg"},
        // 开头填充
        {url: "露雨轩.png"},
        {url: "同庆号普洱茶.png"},
        // {url: "雨林古树茶.png"},
        // {url: "正山堂.png"},
        // {url: "尚羽轩.png"},
        {url: "升格楼.png"},
        {url: "拾年茶馆.png"},
        {url: "水云间.png"},
        {url: "泰州会馆.png"},
        // {url: "天咨雨茶坊.png"},
        {url: "茗福居 (1).png"},
        {url: "澜沧古茶.png"},
        // {url: "mmexport1640240130141.jpg"},
        // {url: "mmexport1641373204621.jpg"},
        // {url: "mmexport1642337175909.jpg"},
        // {url: "mmexport1643215669142.jpg"},
        // {url: "mmexport1643287197915.jpg"},
      ],
      dataList2: [
        // 主体数据结束

        {url: "观之茶隐.png"},
        {url: "大宋茶院.png"},
        {url: "萃辰天心书院.png"},
        {url: "澄园茶坊.png"},
        {url: "古乐茗轩.png"},
        {url: "八马茶业.png"},
        {url: "大益中心.png"},
        {url: "福今茶业.png"},
        {url: "茗福居.png"},
        {url: "涵山舍.png"},
        {url: "和乐轩.png"},
        {url: "养生塘.png"},
        {url: "京华茶苑.png"},
        {url: "儒士馆.png"},
        {url: "mmexport1644135881973.jpg"},
        {url: "mmexport1644331307165.jpg"},
        {url: "mmexport1644331349969.jpg"},
        {url: "QQ截图20220224101702.jpg"},
        //填充
        // 主体数据结束

        {url: "观之茶隐.png"},
        {url: "大宋茶院.png"},
        {url: "萃辰天心书院.png"},
        {url: "澄园茶坊.png"},
        {url: "古乐茗轩.png"},
        {url: "八马茶业.png"},
        {url: "大益中心.png"},
        {url: "福今茶业.png"},
        {url: "茗福居.png"},
        {url: "涵山舍.png"},
        {url: "和乐轩.png"},
        {url: "养生塘.png"},
        {url: "京华茶苑.png"},
        {url: "儒士馆.png"},
        {url: "mmexport1644135881973.jpg"},
        {url: "mmexport1644331307165.jpg"},
        {url: "mmexport1644331349969.jpg"},
        {url: "QQ截图20220224101702.jpg"},
      ],
      timerId: "",
      count: 0,
      stopFlag: false,
      mousePosition_down: "",//鼠标点击时的位置
      contentListWidth: '',//list的宽度
      listLength: 2,//要显示的图片占据的list
      moveFlag: false,//鼠标拖动时，阻止路由跳转
      // mousePosition_up: "",
    };
  },
  methods: {
    unique(arr) {
      return arr.filter((item, index, arr) => {
        return arr.indexOf(item, 0) === index;
      });
    },
    routerLink(item) {
      if (this.moveFlag) return
      this.$router.push(item.path)
    },
    // 鼠标按下时，添加move 和up事件
    contentListMouseDown(e) {
      this.mousePosition_down = e.offsetX;
      this.$refs.contentList.addEventListener("mousemove", this.mouseMove);
      this.$refs.contentList.addEventListener("mouseup", this.mouseUp);
    },
    // 鼠标up时，移除move和up事件
    mouseUp() {
      setTimeout(() => {
        this.moveFlag = false
      }, 0);
      // console.log("up", e.offsetX);
      this.removeListener()

      // 拖动到列表最前面时
      if (this.count < this.contentListWidth) {
        console.log('小')
        this.count = parseInt(this.count) + this.contentListWidth * this.listLength
        // this.$refs.contentList.style.transform = `translateX(-${this.count}px)`;
      }

      // 拖动到列表最后面时
      if (this.count > this.contentListWidth * (this.listLength + 1)) {
        console.log(this.count / this.contentListWidth)
        console.log('最大')
        this.count = parseInt(this.count) - this.listLength * this.contentListWidth
        // this.$refs.contentList.style.transform = `translateX(-${this.count}px)`;
      }
    },
    // 鼠标move时，list跟随移动
    mouseMove(e) {
      this.moveFlag = true
      // console.log("move", e.offsetX);
      let mousePosition_move = e.offsetX;
      this.count = parseInt(this.mousePosition_down) - mousePosition_move + parseInt(this.count);
      // console.log(this.count);
      // this.$refs.contentList.style.transform = `translateX(-${this.count}px)`;
    },
    // 移除contentlist的mousemove 和 mouseup；在鼠标up、鼠标移出此元素、时。
    removeListener() {
      this.$refs.contentList.removeEventListener("mousemove", this.mouseMove);
      this.$refs.contentList.removeEventListener("mouseup", this.mouseUp);
    }
  },
  mounted() {
    // // 定时器滚动
    this.$refs.contentList.addEventListener("mouseenter", () => {
      this.stopFlag = true;
    });
    this.$refs.contentList.addEventListener("mouseleave", () => {
      this.stopFlag = false;
      // 在mouseleave时也移除move和up事件
      this.mouseUp()
    });
    this.contentListWidth = parseInt(getComputedStyle(this.$refs.contentList).width);

    this.count = parseInt(getComputedStyle(this.$refs.contentList).width);

    if (this.count == null) {
      console.log(this.$refs.contentList)
      console.log(getComputedStyle(this.$refs.contentList).width)
      return;
    }

    console.log(this.count);

    this.timerId = setInterval(() => {
      if (this.stopFlag) return;
      this.count++;
      if (this.count === this.contentListWidth * this.listLength) {
        this.count = this.contentListWidth
      }
      this.$refs.contentList.style.transform = `translateX(-${this.count}px)`;
    }, 30);

  },
  beforeDestroy() {
    clearInterval(this.timerId)
  }
};
</script>

<style scoped>
.project-case {
  /*padding-bottom: 3rem;*/
  overflow: hidden;

}

.title {
  font-size: 3.6rem;
  padding: 5rem 0;
}

span {
  cursor: pointer;
}

.content {
}

.content-list {
  user-select: none;
  transform: translateX(-33.33%);
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  /*margin: 1px;*/
}

.content-item {
  padding-right: 0.5em;
  /*width: 80px;*/
  /*height: auto;*/
  max-height: 250px;
}

img {
  transition: all 0.5s;
  width: 100%;
  max-height: 250px;
  cursor: pointer;
  /*margin: 1px;*/
}

img:hover {
  transform: scale(1);
}
</style>
