<template>
  <div class="skills-company-subsection">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <div class="title-content">
          <h2 class="section-title" id="target_1">数字化系统</h2>
        </div>
      </div>
    </div>
    <div class="container" style="width: 80%;">
      <div class="row">
        <div class="col-md-12" style="width: 100%">
          <div class="skills-subsection">
            <div class="skills-subsection-topDiv">
              <!--             基础版 -->
              <div>
                <div class="skills-subsection-div" :style="!boxshow?'height:800px':''">
                  <div class="r1qtA">
                    <div class="_2DmsI">基础版</div>
                    <div class="_1L8tA">
                      适合小型工作室、茶馆、茶店或茶空间，能满足门店收银、员工管理、财务管理、会员管理、库存管理等门店常规需求。
                    </div>
                  </div>
                  <div class="d-flex">
                    <button type="button" class="_2RocW" @click="toContact('system')">立即联系</button>
                  </div>
                  <div class="_2Dxys">
                    <div class="right">
                      <!--<detail-card v-for="tittle in biaozhunbanList" :title="tittle"></detail-card>-->
                    </div>
                    <div class="secondTittle">基础功能：</div>
                    <div class="lv5aU">
                      <div class="_2RocW">员工管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">角色权限</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商品类别</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商品档案（支持500种）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">套餐设置（支持20个）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">包间桌台（支持20个）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">自动计费管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商品会员价</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">仓库\多店异地仓管理（支持1个）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">营业管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">包间自动计时计费</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">预定、开单、增单、赠送、续茶、退单、转台、合并、结账、反结账、挂账、免单</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">点单修改泡茶自动出库量</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客存茶、存课程、取茶 (存取量消息通知)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客存取主人杯</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客开卡、充值、改密、完善档案等常规管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">交接班</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">后厨、水吧打印</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">会员管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员级别
                        (商品折扣率、包间折扣率)
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值档次赠送(可赠送金额、积分、代金券)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员档案管理（支持1000个）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员消费积分，积分兑换</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">消费明细、余额对账</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">财务管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">员工业绩提成报表</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">销售提成:可分服务费提成，商品提成</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值提成：可分首次/非首次充值</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">财务日/周/月/年报表</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">营业数据可视化综合看板</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值统计分析</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商业智能分析、营业报表分析</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">对比近三年\五年，可设定时间段</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">按商品\类别\员工\包间等多维度</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">进行月\年营收对比分析</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        对海量数据进行分类、抽取、挖掘、得到往期和现在经营的全局视图，商业价值多维画像，图表呈现给管理者，提供决策支持
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">短信平台</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">预订、充值扣款余额变化、积分、生日祝福、活动通知、群发管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        系统对接移动、联通、电信三网合一，通知型短信通道（此功能属消耗型，系统提供10条测试短信,如需要继续使用，可以系统上自助购买，根据充值额可有相应赠送，不充值默认为不使用此功能）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">库存管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">实时库存查询</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">随销售自动出库</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">出入库、报损管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">转换整拆零（饼茶撬开成克，按道/壶销售）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">库存盘点：</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">不同销售单位对应不同库存单位不同自动减库量</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">分类别盘点，动态盘点（根据商品设定时间段内有出入变化的，生成盘点任务）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">平库：自动生成盘赢入库、盘亏出库</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">出入库月报表、出入明细，方便库存核对追踪</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">结存汇总报表（按月\季度\ 年出、入、存汇总）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">移动端(微信公众号-小程序)与门店一体化</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        数据微信推送服务（管理端）：
                        推送日报、周报、月报、年报营业数据
                        开单、结账、充值、反结账等重点业务推送股东
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">移动工作（员工端）：开单、结账等业务办理，数据统计，管理自己的会员、查个人业绩，随时进行知识学习
                      </div>
                      <check></check>
                    </div>

                    <div class="lv5aU">
                      <div class="kYjpq">会员充值\消费等余额变化微信推送 使用茶艺人公众号、小程序
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">多组织架构</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">支持单店、总部、大区、分公司、直营店、代理商、加盟店等多组织架构</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">多店订货管理（支持分店在手机端订货，总部手机端审核后发货）</div>
                      <check></check>
                    </div>

                    <div class="lv5aU">
                      <div class="secondTittle">服务</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        客服顾问 7*24h
                      </div>
                      <check></check>
                    </div>

                    <div class="lv5aU">
                      <div class="secondTittle">增值服务（年度）</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        免费对接微信、支付宝收款，支持顾客扫二维码、扫顾客付款码、会员自助充值、公众号商城付款、客人自助结账付款、信用卡、储蓄卡刷卡等
                      </div>
                      <check></check>
                    </div>


                  </div>
                </div>
                <div class="zhankai">
                  <button type="button" class="zhankaiButton" @click="zhankai()" v-show="!boxshow">展开</button>
                  <button type="button" class="zhankaiButton" @click="zhankai()" v-show="boxshow"
                          v-smooth-scroll="{ duration: 1000,offset: -150 }" href="#target_1">收起
                  </button>
                </div>
              </div>
              <!--             专业版 -->
              <div>
                <div class="skills-subsection-div" :style="!boxshow?'height:800px':''">
                  <div class="r1qtA">
                    <div class="_2DmsI">专业版</div>
                    <div class="_1L8tA">
                      适合成长型茶馆、茶楼、品牌专营店，在基础版本上，提供了更多品类和管理维度以及运营工具，空间多样计费、智能数据分析，成本利润核算，实现线上会员深度服务和触达、代金劵引流、与线下一体化经营管理。
                    </div>
                  </div>
                  <div class="d-flex">
                    <button type="button" class="_2RocW" @click="toContact('system')">立即联系</button>
                  </div>
                  <div class="_2Dxys">
                    <div class="right">
                    </div>
                    <div class="secondTittle">基础功能：</div>
                    <div class="lv5aU">
                      <div class="_2RocW">员工管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">角色权限</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商品类别</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商品档案（无上限）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">套餐设置（无上限）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">包间桌台（无上限）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">自动计费管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商品会员价</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">仓库\多店异地仓管理（支持3个）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">营业管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">预定、开单、增单、赠送、续茶、退单、转台、合并、结账、反结账、挂账、免单</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">点单修改泡茶自动出库量</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">点单时修改出库仓库</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">点单时可多选销售员</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客存茶、存课程、取茶 (存取量消息通知)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客存取主人杯（物）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客开卡、充值、改密、完善档案等常规管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">会员私密画像填报</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">吧台支出</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">交接班</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">后厨、水吧打印</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">会员管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员级别
                        (商品折扣率、包间折扣率)
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值档次赠送(可赠送金额、积分、代金券)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员档案管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员爱好公开属性</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">会员画像私密参数设置</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">3个月(可设置)未消费会员，唤醒消费</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">3个月(可设置)有消费客户，回馈消费</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员消费积分，积分兑换</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员累计消费升级</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员生日提醒</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员生日当天消费多倍积分(可配置)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">线上粉丝抓取转会员</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员代金券、礼品卡</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">消费频次、充值额、消费额排行、末次充值和末次消费时间的统计等</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">消费明细、余额对账</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">财务管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">员工业绩提成报表</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">销售提成:可分服务费提成，商品提成</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值提成：可分首次/非首次充值</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">同一个销售单或商品多员工提成</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">成本和利润管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">财务日/周/月/年报表</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">财务支出（房租\水电费\工资\管理费用等）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">营业数据可视化综合看板</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值统计分析</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商业智能分析、营业报表分析</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">对比近三年\五年，可设定时间段</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">按商品\类别\员工\包间等多维度</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">进行月\年营收对比分析</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        对海量数据进行分类、抽取、挖掘、得到往期和现在经营的全局视图，商业价值多维画像，图表呈现给管理者，提供决策支持。
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">短信平台</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">预订、充值扣款余额变化、积分、生日祝福、活动通知、群发管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        系统对接移动、联通、电信三网合一，通知型短信通道（此功能属消耗型，系统提供10条测试短信,如需要继续使用，可以系统上自助购买，根据充值额可有相应赠送，不充值默认为不使用此功能）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">库存管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">实时库存查询</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">随销售自动出库</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">出入库、报损管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">库存调拨与统计</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">转换整拆零（饼茶撬开成克，按道/壶销售）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">库存盘点：</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">不同销售单位对应不同库存单位不同自动减库量</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">分类别盘点，动态盘点（根据商品设定时间段内有出入变化的，生成盘点任务）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">平库：自动生成盘赢入库、盘亏出库</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">出入库月报表、出入明细，方便库存核对追踪</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">库存预警</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">结存汇总报表（按月\季度\ 年出、入、存汇总）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">出库\入库\调拨 支持一级审核</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">移动端(微信公众号-小程序)与门店一体化</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        数据微信推送服务（管理端）：
                        推送日报、周报、月报、年报营业数据
                        开单、结账、充值、反结账等重点业务推送股东
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">移动工作（员工端）：开单、结账等业务办理，数据统计，管理自己的会员、查个人业绩，随时进行知识学习
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">会员充值\消费等余额变化微信推送
                        （使用 商户公众号\小程序）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">微信粉丝自动抓取转会员
                        （使用 商户公众号\小程序）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">微信小程序（会员服务）</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">自助扫码点单</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">自助充值</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">自助查询消费/充值记录，余额对账</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">包间预订、电子茶单</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">接收预订、消费记录、订单、代金券、礼品卡等的账户余额和业务变化通知
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">存茶、茶杯等数据清晰明了</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">多组织架构</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">支持单店、总部、大区、分公司、直营店、代理商、加盟店等多组织架构</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">多店订货管理（支持分店在手机端订货，总部手机端审核后发货）</div>
                      <check></check>
                    </div>

                    <div class="lv5aU">
                      <div class="secondTittle">服务</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        客服专员 7*24h
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">增值服务（年度）</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        免费对接微信、支付宝收款，支持顾客扫二维码、扫顾客付款码、会员自助充值、公众号商城付款、客人自助结账付款、信用卡、储蓄卡刷卡等
                      </div>
                      <check></check>
                    </div>
                  </div>
                </div>
                <div class="zhankai">
                  <button type="button" class="zhankaiButton" @click="zhankai()" v-show="!boxshow">展开</button>
                  <button type="button" class="zhankaiButton" @click="zhankai()" v-show="boxshow"
                          v-smooth-scroll="{ duration: 1000,offset: -150 }" href="#target_1">收起
                  </button>
                </div>
              </div>
              <!--             旗舰版 -->
              <div>
                <div class="skills-subsection-div" :style="!boxshow?'height:800px':''">
                  <div class="r1qtA">
                    <div class="_2DmsI">旗舰版</div>
                    <div class="_1L8tA">
                      适合专业茶楼、茶店、茶企，在专业版基础上提供更丰富的营销和运营工具，涨粉进店、礼品卡（类似大闸蟹卡）精准营销、批发、采购管理、支持多仓库精细化库存管理、线上一体化商城、打造会员离店服务，利用数字化全面赋能管理需求的茶楼和茶企等。
                    </div>
                  </div>
                  <div class="d-flex">
                    <button type="button" class="_2RocW" @click="toContact('system')">立即联系</button>
                  </div>
                  <div class="_2Dxys">
                    <div class="right">
                    </div>
                    <div class="secondTittle">基础功能：</div>
                    <div class="lv5aU">
                      <div class="_2RocW">员工管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">角色权限</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商品类别</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商品档案</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">套餐设置</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">包间桌台</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">自动计费管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商品会员价</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">仓库\多店异地仓管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">营业管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">预定、开单、增单、赠送、续茶、退单、转台、合并、结账、反结账、挂账、免单</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">点单修改泡茶自动出库量</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">点单时修改出库仓库</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">点单时可多选销售员</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客存茶、存课程、取茶 (存取量消息通知)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客开卡、充值、改密、完善档案等常规管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员私密画像填报</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">吧台支出</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">交接班</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">后厨、水吧打印</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">会员管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员级别
                        (商品折扣率、包间折扣率)
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值档次赠送(可赠送金额、积分、代金券)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员消费累销升级</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员档案管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员爱好公开属性</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员画像私密参数设置</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值消费微信通知</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">3个月(可设置)未消费会员，唤醒消费</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">3个月(可设置)有消费客户，回馈消费</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员消费积分，积分兑换</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员累计消费升级</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员生日提醒</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员生日当天消费多倍积分(可配置)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">线上粉丝抓取转会员</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员代金券</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">消费频次、充值额、消费额排行、末次充值和末次消费时间的统计等</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">消费明细、余额对账</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">财务管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">员工业绩提成报表</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">销售提成:可分服务费提成，商品提成</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值提成：可分首次/非首次充值</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">同一个销售单或商品多员工提成</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">成本和利润管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">财务日/周/月/年报表</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">财务支出（房租\水电费\工资\管理费用等）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">营业数据可视化综合看板</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值统计分析</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">推送日报、周报、月报、年报营业数据</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商业智能分析、营业报表分析</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">对比近三年\五年，可设定时间段</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">按商品\类别\员工\包间等多维度</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">进行月\年营收对比分析</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        对海量数据进行分类、抽取、挖掘、得到往期和现在经营的全局视图，商业价值多维画像，图表呈现给管理者，提供决策支持
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">短信平台</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客预订短信</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客存、取茶短信</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">预订、充值扣款余额变化、积分、生日祝福、活动通知、群发管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        系统对接移动、联通、电信三网合一，通知型短信通道(此功能属消耗型，系统提供10条测试短信,如需要继续使用，可以系统上自助购买，根据充值额可有相应赠送，不充值默认为不使用此功能)
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">库存管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">实时库存查询</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">随销售自动出库</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">出入库管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">出入库、报损管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">库存调拨与统计</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">转换整拆零（饼茶撬开成克，按道/壶销售）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">库存盘点：</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">不同销售单位对应不同库存单位不同自动减库量</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">分类别盘点，动态盘点（根据商品设定时间段内有出入变化的，生成盘点任务）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">平库：自动生成盘赢入库、盘亏出库</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">出入库月报表、出入明细，方便库存核对追踪</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">库存预警</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">结存汇总报表（按月\季度\ 年出、入、存汇总）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">出库\入库\调拨 一级审核</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">移动端(微信公众号-小程序)与门店一体化</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        移动工作（管理端）：移动办公，店内数据根据权限随时查看，可自动接收日/周/月/年报表（有开关）
                        （使用 金米茶艺人公众号\金米茶艺人小程序）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">移动工作（员工端）：开单结账、管理自己的会员、查个人业绩，随时进行知识学习
                        （使用 金米茶艺人公众号\金米茶艺人小程序）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值\消费等余额变化微信推送
                        （使用 商户公众号\小程序）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">微信粉丝自动抓取转会员
                        （使用 商户公众号\小程序）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">微信小程序（会员服务）
                        （使用 商户公众号\小程序）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">自助充值</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">自助扫码点单</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">自助查询消费/充值记录，余额对账</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">包间预订</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">存茶、茶杯等数据清晰明了</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">线上商城</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">商户公众号\小程序</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">商城后台</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">茶会发布</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">课程发布</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">
                        系统自动记录浏览顾客的手机号、微信头像、昵称、浏览时间、浏览次数信息，为门店引流提供数据
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">设置：5张门店轮播广告图，门店特色介绍</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">设置：商品类别图、类别顺序、上下架</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">设置：商品定价/上下架、推荐、热销、售罄</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">
                        设置商品的详细介绍，最多支持22张图片（含1张推荐大图、1张主图、3张轮播、17张详情图）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">
                        订单审核、发货(自动减库存)管理，快递公司选择，自动推送订单状态消息给顾客
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">顾客发票管理</div>
                      <check></check>
                    </div>

                    <div class="lv5aU">
                      <div class="kYjpq secondTittle">微信小程序商城（顾客端）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">主页：显示门店地点、营业时间、营业状态、距离、门店导航、联系门店、</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">
                        主页：显示门店地点、营业时间、营业状态、距离、门店导航、联系门店、商品搜索、门店5张广告轮播图、推荐商品、热销商品
                      </div>
                      <check></check>
                    </div>

                    <div class="lv5aU">
                      <div class="kYjpq important_1">
                        商品显示会员价，下单可明显显示会员优惠，充值会员可享受与店内同等折扣。
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">结算主推储值卡扣款，实现从访客到充值会员的引流</div>
                      <check></check>
                    </div>

                    <div class="lv5aU">
                      <div class="kYjpq important_1">商城订单支持快递发货和到店自取</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">商城订单可申请发票</div>
                      <check></check>
                    </div>

                    <div class="lv5aU">
                      <div class="kYjpq important_1">线上代金劵发放</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">礼品卡销售兑换（类似大闸蟹卡）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">采购和供应商管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">供应商档案管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">采购预付款管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">入库单付款管理(使用预付款，多批次付款)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">采购报表分析，预付款、已付款、应付款统计</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">批发和代理商管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">代理商档案管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">预收代理商定金</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">批发销售单管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">批发单付款(使用预订款、多批次付款)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">批发报表统计，批发营业报表、预收款、应收款统计</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">服务</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        客服专员+技术经理+运营经理 7*24h
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">多组织架构</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">支持单店、总部、大区、分公司、直营店、代理商、加盟店等多组织架构</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">增值服务（年度）</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">门店发起拼团购</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        免费对接微信、支付宝收款，支持顾客扫二维码、扫顾客付款码、会员自助充值、公众号商城付款、客人自助结账付款、信用卡、储蓄卡刷卡等
                      </div>
                      <check></check>
                    </div>
                  </div>
                </div>
                <div class="zhankai">
                  <button type="button" class="zhankaiButton" @click="zhankai()" v-show="!boxshow">展开</button>
                  <button type="button" class="zhankaiButton" @click="zhankai()" v-show="boxshow"
                          v-smooth-scroll="{ duration: 1000,offset: -150 }" href="#target_1">收起
                  </button>
                </div>
              </div>
              <!--              至尊版-->
              <div>
                <div class="skills-subsection-div" :style="!boxshow?'height:800px':''">
                  <div class="r1qtA">
                    <div class="_2DmsI">至尊版</div>
                    <div class="_1L8tA">
                      适合品牌茶楼、茶企、茶艺培训机构。旗舰版增加了茶会管理、课程管理、预售、团购活动和运营辅导等多项功能，在全面数字化的基础上更好地进行品牌推广
                    </div>
                  </div>
                  <div class="d-flex">
                    <button type="button" class="_2RocW" @click="toContact('system')">立即联系</button>
                  </div>
                  <div class="_2Dxys">
                    <div class="secondTittle">基础功能：</div>
                    <div class="lv5aU">
                      <div class="_2RocW">员工管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">角色权限</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商品类别</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商品档案</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">套餐设置</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">包间桌台</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">自动计费管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商品会员价</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">仓库\多店异地仓管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">营业管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">预定、开单、增单、赠送、续茶、退单、转台、合并、结账、反结账、挂账、免单</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">点单修改泡茶自动出库量</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">点单时修改出库仓库</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">点单时可多选销售员</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客存茶、取茶(存取量消息通知)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客存取主人杯(物)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客开卡、充值、改密、完善档案等常规管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员私密画像填报</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">吧台支出</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">交接班</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">后厨、水吧打印</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">会员管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员级别(商品折扣率、包间折扣率)
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值档次赠送(可赠送金额、积分、代金券)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员累计消费升级</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员档案管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员爱好公开属性</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员画像私密参数设置</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">3个月(可设置)未消费会员，唤醒消费</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">3个月(可设置)有消费客户，回馈消费</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员消费积分，积分兑换</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员生日提醒</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员生日当天消费多倍积分(可配置)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">线上粉丝抓取转会员</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员代金券</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">消费频次、充值额、消费额排行、末次充值和末次消费时间的统计等</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">消费明细、余额对账</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">财务管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">员工业绩提成报表</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">销售提成:可分服务费提成，商品提成</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值提成：可分首次/非首次充值</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">同一个销售单或商品多员工提成</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">成本和利润管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">财务日/周/月/年报表</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">财务支出（房租\水电费\工资\管理费用等）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">营业数据可视化综合看板</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值统计分析</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">商业智能分析、营业报表分析</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">对比近三年\五年，可设定时间段</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">按商品\类别\员工\包间等多维度</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">进行月\年营收对比分析</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        对海量数据进行分类、抽取、挖掘、得到往期和现在经营的全局视图，商业价值多维画像，图表呈现给管理者，提供决策支持
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">短信平台</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客预订短信</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客存、取茶短信</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">预订、充值扣款余额变化、积分、生日祝福、活动通知、群发管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        系统对接移动、联通、电信三网合一，通知型短信通道(此功能属消耗型，系统提供10条测试短信,如需要继续使用，可以系统上自助购买，根据充值额可有相应赠送，不充值默认为不使用此功能)
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">库存管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">实时库存查询</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">随销售自动出库</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">出入库、报损管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">库存调拨与统计</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">转换整拆零（饼茶撬开成克，按道/壶销售）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">库存盘点：</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">不同销售单位对应不同库存单位不同自动减库量</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">分类别盘点，动态盘点（根据商品设定时间段内有出入变化的，生成盘点任务）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">平库：自动生成盘赢入库、盘亏出库</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">出入库月报表、出入明细，方便库存核对追踪</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">库存预警</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">结存汇总报表（按月\季度\ 年出、入、存汇总）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">出库\入库\调拨一级审核、二级审核</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">移动端(微信公众号-小程序)与门店一体化</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        数据微信推送服务（管理端）：
                        推送日报、周报、月报、年报营业数据
                        开单、结账、充值、反结账等重点业务推送股东
                        （使用 金米茶艺人公众号\金米茶艺人小程序）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">移动工作（员工端）：开单、结账等业务办理，数据统计，管理自己的会员、查个人业绩，随时进行知识学习
                        （使用 金米茶艺人公众号\金米茶艺人小程序）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">会员充值\消费等余额变化微信推送
                        （使用 商户公众号\小程序 ）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">微信粉丝自动抓取转会员
                        （使用 商户公众号\小程序 ）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">微信小程序（会员服务）
                        （使用 商户公众号\小程序 ）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">自助扫码点单</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">自助充值</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">自助查询消费/充值记录，余额对账</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">包间预订</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">存茶、茶杯等数据清晰明了</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">顾客自助结账，散客价格与会员价格优惠对比明显，吸引储值</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">线上商城</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">商户公众号\小程序</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">商城后台</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">茶会发布</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">课程发布</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">
                        系统自动记录浏览顾客的手机号、微信头像、昵称、浏览时间、浏览次数信息，为门店引流提供数据
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">设置：5张门店轮播广告图，门店特色介绍</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">设置：商品类别图、类别顺序、上下架</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">设置：商品定价/上下架、推荐、热销、售罄</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">
                        设置商品的详细介绍，最多支持22张图片（含1张推荐大图、1张主图、3张轮播、17张详情图）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">
                        订单审核、发货(自动减库存)管理，快递公司选择，自动推送订单状态消息给顾客
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">顾客发票管理</div>
                      <check></check>
                    </div>

                    <div class="lv5aU">
                      <div class="kYjpq secondTittle">微信小程序商城（顾客端）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">主页：显示门店地点、营业时间、营业状态、距离、门店导航、联系门店、</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">
                        主页：显示门店地点、营业时间、营业状态、距离、门店导航、联系门店、商品搜索、门店5张广告轮播图、推荐商品、热销商品
                      </div>
                      <check></check>
                    </div>

                    <div class="lv5aU">
                      <div class="kYjpq important_1">
                        商品显示会员价，下单可明显显示会员优惠，充值会员可享受与店内同等折扣。
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">结算主推储值卡扣款，实现从访客到充值会员的引流</div>
                      <check></check>
                    </div>

                    <div class="lv5aU">
                      <div class="kYjpq important_1">商城订单支持快递发货和到店自取</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">商城订单可申请发票</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">线上代金劵发放</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">礼品卡销售兑换（类似大闸蟹卡）</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle important_1">课程、茶会管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">课程发布、邀约报名、
                        课程码线下核销、收款等全流程管理（使用 商户公众号\小程序）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq important_1">茶会发布、邀约报名、茶会码线下核销、
                        收款等全流程管理（使用 商户公众号\小程序）
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">采购和供应商管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">供应商档案管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">采购预付款管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">入库单付款管理(使用预付款，多批次付款)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">采购报表分析，预付款、已付款、应付款统计</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">批发和代理商管理</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">代理商档案管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">预收代理商定金</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">批发销售单管理</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">批发单付款(使用预订款、多批次付款)</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq ">批发报表统计，批发营业报表、预收款、应收款统计</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">服务</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        客服总监+技术总监+运营总监 7*24h
                      </div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">多组织构架</div>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">支持单店、总部、大区、分公司、直营店、代理商、加盟店等多组织架构</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="secondTittle">增值服务（年度）</div>
                    </div>

                    <div class="lv5aU">
                      <div class="kYjpq important_1">门店发起拼团购</div>
                      <check></check>
                    </div>
                    <div class="lv5aU">
                      <div class="kYjpq">
                        免费对接微信、支付宝收款，支持顾客扫二维码、扫顾客付款码、会员自助充值、公众号商城付款、客人自助结账付款、信用卡、储蓄卡刷卡等
                      </div>
                      <check></check>
                    </div>
                  </div>
                </div>
                <div class="zhankai">
                  <button type="button" class="zhankaiButton" @click="zhankai()" v-show="!boxshow">展开</button>
                  <button type="button" class="zhankaiButton" @click="zhankai()" v-show="boxshow"
                          v-smooth-scroll="{ duration: 1000,offset: -150 }" href="#target_1">收起
                  </button>
                </div>
              </div>
            </div>
            <div class="row" style="padding-top: 45px">
              <div class="col-md-8 col-md-offset-2">
                <div class="title-content">
                  <h2 class="section-title">托管运营</h2>
                </div>
              </div>
            </div> <!-- /.row -->
            <!--                                托管运营-->
            <div class="freeDiv">
              <div class="freeDiv_div" style="display: inline-block">
                <div class="right" style="color: black">
                  <div>
                    <div style="display: inline-block;vertical-align: top;font-size: 16px;font-weight: 800">
                      标准版:
                    </div>
                    <div style="display: inline-block;width: 90%">
                      适合有茶师团队，有主营产品，经营目标明确，缺乏系统性专业知识和应用技巧的茶楼茶馆
                    </div>
                    <div class="group_dot">
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">茶类基础知识培训
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">冲泡技巧培训
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">销售技巧培训
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="display: inline-block;vertical-align: top;font-size: 16px;font-weight: 800">
                      专业版:
                    </div>
                    <div style="display: inline-block;width: 90%">
                      适合初创成长型、非茶行业投资人(老板是外行)缺乏系统管理经验和运营团队的茶馆茶楼，提供门店管理制度，协助招聘培训茶师和运营团队
                    </div>
                    <div class="group_dot">
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">茶类基础知识培训
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">冲泡技巧培训
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">销售技巧培训
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">管理制度培训
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">管理工具的建立和使用(配合管理制度)
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">团队建设
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="display: inline-block;vertical-align: top;font-size: 16px;font-weight: 800">
                      旗舰版:
                    </div>
                    <div style="display: inline-block;width: 90%">
                      适合中大型旗舰门店，想打造品牌影响力及产品标准，老板对门店格调和品牌定位有较高要求的专业品牌茶馆茶楼
                    </div>
                    <div class="group_dot">
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">茶类基础知识培训
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">冲泡技巧培训
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">销售技巧培训
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">管理制度培训
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">管理工具的建立和使用(配合管理制度)
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">团队建设
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">产品设计
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">营销推广
                      </div>
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">品牌定位、门店营销、客群分析和管理策略
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="display: inline-block;vertical-align: top;font-size: 16px;font-weight: 800">
                      托管版:
                    </div>
                    <div style="display: inline-block;width: 90%">
                      适合资源型茶馆、茶楼、茶会所，缺乏茶行业或门店经营管理经验或精力，主要实现资源聚集和转化
                    </div>
                    <div class="group_dot">
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">门店具备完备的经营条件，且有明确的经营诉求但不想投入过多时间和精力参与到门店日常琐碎的管理过程中;我方可根据门店需求，提供人员团队、销售产品、常规运营等全面合作服务，让您轻松拥有一间规范、舒适、专业的茶馆空间
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="display: inline-block;vertical-align: top;font-size: 16px;font-weight: 800">
                      茶企版:
                    </div>
                    <div style="display: inline-block;width: 90%">
                      适合有系列产品，有渠道或计划建设渠道(直营、加盟)的茶企，提供企业培训和市场运营人员的团队搭建和管理优化方案
                    </div>
                    <div class="group_dot">
                      <div>
                        <img class="imgDots" src="../../assets/点.png" alt="">针对具有一定渠道规模的茶企、茶店，我们通过金米管理系统，有效打通总部与渠道的实时关联，再结合茶企品牌产品的市场特性，制定规范一套适合茶企产品的销售术语和模式，提升渠道终端销售能力;同时帮助茶企搭建至少1-2人的专业运营团队，能够长期持续的对终端提供服务和赋能
                      </div>
                    </div>
                  </div>


                </div>
                <div class="r1qtA">
                  <div class="_2DmsI">扫码了解详情</div>
                  <div style="width: 100%;height: 200px;text-align: center;padding: 10px">

                    <img src="../../assets/QRC/QRC3.png" alt=""
                         style="width: 100px;display: inline-block;border-radius: 12px;margin-top: 50px;">
                  </div>
                  <div class="d-flex" data-v-4711d3a4="">
                    <button type="button" class="_2RocW" data-v-4711d3a4="" @click="toContact('yunYing')">立即联系
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> <!-- /.skills-subsection -->
        </div>
      </div> <!-- /.row -->
    </div> <!-- /.container -->
    <div class="phoneContent">
      <div class="tabsGroup">
        <ul>
          <!--          <li @click="squareMouse(1)" :class="{'tabsGroup_ul_li_active':squareIndex === 1}">免费版</li>-->
          <li @click="squareMouse(2)" :class="{'tabsGroup_ul_li_active':squareIndex === 2}">标准版</li>
          <li @click="squareMouse(3)" :class="{'tabsGroup_ul_li_active':squareIndex === 3}">专业版</li>
          <li @click="squareMouse(4)" :class="{'tabsGroup_ul_li_active':squareIndex === 4}">旗舰版</li>
          <li @click="squareMouse(5)" :class="{'tabsGroup_ul_li_active':squareIndex === 5}">至尊版</li>
        </ul>
      </div>
      <div class="pageGroup">
        <div class="pageGroup_div" v-show="squareIndex === 2">
          <div>
            <div class="skills-subsection-div" :style="!boxshow?'height:800px':''">
              <div class="r1qtA">
                <div class="_2DmsI">基础版</div>
                <div class="_1L8tA">
                  适合小型工作室、茶馆、茶店或茶空间，能满足门店收银、员工管理、财务管理、会员管理、库存管理等门店常规需求。
                </div>
              </div>
              <div class="d-flex">
                <button type="button" class="_2RocW" @click="toContact('system')">立即联系</button>
              </div>
              <div class="_2Dxys">
                <div class="right">
                  <!--<detail-card v-for="tittle in biaozhunbanList" :title="tittle"></detail-card>-->
                </div>
                <div class="secondTittle">基础功能：</div>
                <div class="lv5aU">
                  <div class="_2RocW">员工管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">角色权限</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商品类别</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商品档案（支持500种）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">套餐设置（支持20个）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">包间桌台（支持20个）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">自动计费管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商品会员价</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">仓库\多店异地仓管理（支持1个）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">营业管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">包间自动计时计费</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">预定、开单、增单、赠送、续茶、退单、转台、合并、结账、反结账、挂账、免单</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">点单修改泡茶自动出库量</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客存茶、存课程、取茶 (存取量消息通知)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客存取主人杯</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客开卡、充值、改密、完善档案等常规管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">交接班</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">后厨、水吧打印</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">会员管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员级别
                    (商品折扣率、包间折扣率)
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值档次赠送(可赠送金额、积分、代金券)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员档案管理（支持1000个）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员消费积分，积分兑换</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">消费明细、余额对账</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">财务管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">员工业绩提成报表</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">销售提成:可分服务费提成，商品提成</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值提成：可分首次/非首次充值</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">财务日/周/月/年报表</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">营业数据可视化综合看板</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值统计分析</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商业智能分析、营业报表分析</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">对比近三年\五年，可设定时间段</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">按商品\类别\员工\包间等多维度</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">进行月\年营收对比分析</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    对海量数据进行分类、抽取、挖掘、得到往期和现在经营的全局视图，商业价值多维画像，图表呈现给管理者，提供决策支持
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">短信平台</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">预订、充值扣款余额变化、积分、生日祝福、活动通知、群发管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    系统对接移动、联通、电信三网合一，通知型短信通道（此功能属消耗型，系统提供10条测试短信,如需要继续使用，可以系统上自助购买，根据充值额可有相应赠送，不充值默认为不使用此功能）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">库存管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">实时库存查询</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">随销售自动出库</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">出入库、报损管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">转换整拆零（饼茶撬开成克，按道/壶销售）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">库存盘点：</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">不同销售单位对应不同库存单位不同自动减库量</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">分类别盘点，动态盘点（根据商品设定时间段内有出入变化的，生成盘点任务）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">平库：自动生成盘赢入库、盘亏出库</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">出入库月报表、出入明细，方便库存核对追踪</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">结存汇总报表（按月\季度\ 年出、入、存汇总）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">移动端(微信公众号-小程序)与门店一体化</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    数据微信推送服务（管理端）：
                    推送日报、周报、月报、年报营业数据
                    开单、结账、充值、反结账等重点业务推送股东
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">移动工作（员工端）：开单、结账等业务办理，数据统计，管理自己的会员、查个人业绩，随时进行知识学习
                  </div>
                  <check></check>
                </div>

                <div class="lv5aU">
                  <div class="kYjpq">会员充值\消费等余额变化微信推送 使用茶艺人公众号、小程序
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">多组织架构</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">支持单店、总部、大区、分公司、直营店、代理商、加盟店等多组织架构</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">多店订货管理（支持分店在手机端订货，总部手机端审核后发货）</div>
                  <check></check>
                </div>

                <div class="lv5aU">
                  <div class="secondTittle">服务</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    客服顾问 7*24h
                  </div>
                  <check></check>
                </div>

                <div class="lv5aU">
                  <div class="secondTittle">增值服务（年度）</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    免费对接微信、支付宝收款，支持顾客扫二维码、扫顾客付款码、会员自助充值、公众号商城付款、客人自助结账付款、信用卡、储蓄卡刷卡等
                  </div>
                  <check></check>
                </div>
              </div>
            </div>
            <div class="zhankai">
              <button type="button" class="zhankaiButton" @click="zhankai()" v-show="!boxshow">展开</button>
              <button type="button" class="zhankaiButton" @click="zhankai()" v-show="boxshow"
                      v-smooth-scroll="{ duration: 1000,offset: -150 }" href="#target_1">收起
              </button>
            </div>
          </div>
        </div>
        <div class="pageGroup_div" v-show="squareIndex === 3">
          <div>
            <div class="skills-subsection-div" :style="!boxshow?'height:800px':''">
              <div class="r1qtA">
                <div class="_2DmsI">专业版</div>
                <div class="_1L8tA">
                  适合成长型茶馆、茶楼、品牌专营店，在基础版本上，提供了更多品类和管理维度以及运营工具，空间多样计费、智能数据分析，成本利润核算，实现线上会员深度服务和触达、代金劵引流、与线下一体化经营管理。
                </div>
              </div>
              <div class="d-flex">
                <button type="button" class="_2RocW" @click="toContact('system')">立即联系</button>
              </div>
              <div class="_2Dxys">
                <div class="right">
                </div>
                <div class="secondTittle">基础功能：</div>
                <div class="lv5aU">
                  <div class="_2RocW">员工管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">角色权限</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商品类别</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商品档案（无上限）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">套餐设置（无上限）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">包间桌台（无上限）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">自动计费管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商品会员价</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">仓库\多店异地仓管理（支持3个）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">营业管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">预定、开单、增单、赠送、续茶、退单、转台、合并、结账、反结账、挂账、免单</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">点单修改泡茶自动出库量</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">点单时修改出库仓库</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">点单时可多选销售员</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客存茶、存课程、取茶 (存取量消息通知)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客存取主人杯（物）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客开卡、充值、改密、完善档案等常规管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">会员私密画像填报</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">吧台支出</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">交接班</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">后厨、水吧打印</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">会员管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员级别
                    (商品折扣率、包间折扣率)
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值档次赠送(可赠送金额、积分、代金券)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员档案管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员爱好公开属性</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">会员画像私密参数设置</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">3个月(可设置)未消费会员，唤醒消费</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">3个月(可设置)有消费客户，回馈消费</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员消费积分，积分兑换</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员累计消费升级</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员生日提醒</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员生日当天消费多倍积分(可配置)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">线上粉丝抓取转会员</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员代金券、礼品卡</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">消费频次、充值额、消费额排行、末次充值和末次消费时间的统计等</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">消费明细、余额对账</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">财务管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">员工业绩提成报表</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">销售提成:可分服务费提成，商品提成</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值提成：可分首次/非首次充值</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">同一个销售单或商品多员工提成</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">成本和利润管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">财务日/周/月/年报表</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">财务支出（房租\水电费\工资\管理费用等）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">营业数据可视化综合看板</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值统计分析</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商业智能分析、营业报表分析</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">对比近三年\五年，可设定时间段</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">按商品\类别\员工\包间等多维度</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">进行月\年营收对比分析</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    对海量数据进行分类、抽取、挖掘、得到往期和现在经营的全局视图，商业价值多维画像，图表呈现给管理者，提供决策支持。
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">短信平台</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">预订、充值扣款余额变化、积分、生日祝福、活动通知、群发管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    系统对接移动、联通、电信三网合一，通知型短信通道（此功能属消耗型，系统提供10条测试短信,如需要继续使用，可以系统上自助购买，根据充值额可有相应赠送，不充值默认为不使用此功能）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">库存管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">实时库存查询</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">随销售自动出库</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">出入库、报损管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">库存调拨与统计</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">转换整拆零（饼茶撬开成克，按道/壶销售）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">库存盘点：</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">不同销售单位对应不同库存单位不同自动减库量</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">分类别盘点，动态盘点（根据商品设定时间段内有出入变化的，生成盘点任务）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">平库：自动生成盘赢入库、盘亏出库</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">出入库月报表、出入明细，方便库存核对追踪</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">库存预警</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">结存汇总报表（按月\季度\ 年出、入、存汇总）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">出库\入库\调拨 支持一级审核</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">移动端(微信公众号-小程序)与门店一体化</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    数据微信推送服务（管理端）：
                    推送日报、周报、月报、年报营业数据
                    开单、结账、充值、反结账等重点业务推送股东
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">移动工作（员工端）：开单、结账等业务办理，数据统计，管理自己的会员、查个人业绩，随时进行知识学习
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">会员充值\消费等余额变化微信推送
                    （使用 商户公众号\小程序）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">微信粉丝自动抓取转会员
                    （使用 商户公众号\小程序）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">微信小程序（会员服务）</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">自助扫码点单</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">自助充值</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">自助查询消费/充值记录，余额对账</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">包间预订、电子茶单</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">接收预订、消费记录、订单、代金券、礼品卡等的账户余额和业务变化通知</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">存茶、茶杯等数据清晰明了</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">多组织架构</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">支持单店、总部、大区、分公司、直营店、代理商、加盟店等多组织架构</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">多店订货管理（支持分店在手机端订货，总部手机端审核后发货）</div>
                  <check></check>
                </div>

                <div class="lv5aU">
                  <div class="secondTittle">服务</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    客服专员 7*24h
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">增值服务（年度）</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    免费对接微信、支付宝收款，支持顾客扫二维码、扫顾客付款码、会员自助充值、公众号商城付款、客人自助结账付款、信用卡、储蓄卡刷卡等
                  </div>
                  <check></check>
                </div>
              </div>
            </div>
            <div class="zhankai">
              <button type="button" class="zhankaiButton" @click="zhankai()" v-show="!boxshow">展开</button>
              <button type="button" class="zhankaiButton" @click="zhankai()" v-show="boxshow"
                      v-smooth-scroll="{ duration: 1000,offset: -150 }" href="#target_1">收起
              </button>
            </div>
          </div>
        </div>
        <div class="pageGroup_div" v-show="squareIndex === 4">
          <div>
            <div class="skills-subsection-div" :style="!boxshow?'height:800px':''">
              <div class="r1qtA">
                <div class="_2DmsI">旗舰版</div>
                <div class="_1L8tA">
                  适合专业茶楼、茶店、茶企，在专业版基础上提供更丰富的营销和运营工具，涨粉进店、礼品卡（类似大闸蟹卡）精准营销、批发、采购管理、支持多仓库精细化库存管理、线上一体化商城、打造会员离店服务，利用数字化全面赋能管理需求的茶楼和茶企等。
                </div>
              </div>
              <div class="d-flex">
                <button type="button" class="_2RocW" @click="toContact('system')">立即联系</button>
              </div>
              <div class="_2Dxys">
                <div class="right">
                </div>
                <div class="secondTittle">基础功能：</div>
                <div class="lv5aU">
                  <div class="_2RocW">员工管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">角色权限</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商品类别</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商品档案</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">套餐设置</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">包间桌台</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">自动计费管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商品会员价</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">仓库\多店异地仓管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">营业管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">预定、开单、增单、赠送、续茶、退单、转台、合并、结账、反结账、挂账、免单</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">点单修改泡茶自动出库量</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">点单时修改出库仓库</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">点单时可多选销售员</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客存茶、存课程、取茶 (存取量消息通知)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客开卡、充值、改密、完善档案等常规管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员私密画像填报</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">吧台支出</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">交接班</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">后厨、水吧打印</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">会员管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员级别
                    (商品折扣率、包间折扣率)
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值档次赠送(可赠送金额、积分、代金券)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员消费累销升级</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员档案管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员爱好公开属性</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员画像私密参数设置</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值消费微信通知</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">3个月(可设置)未消费会员，唤醒消费</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">3个月(可设置)有消费客户，回馈消费</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员消费积分，积分兑换</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员累计消费升级</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员生日提醒</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员生日当天消费多倍积分(可配置)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">线上粉丝抓取转会员</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员代金券</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">消费频次、充值额、消费额排行、末次充值和末次消费时间的统计等</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">消费明细、余额对账</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">财务管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">员工业绩提成报表</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">销售提成:可分服务费提成，商品提成</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值提成：可分首次/非首次充值</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">同一个销售单或商品多员工提成</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">成本和利润管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">财务日/周/月/年报表</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">财务支出（房租\水电费\工资\管理费用等）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">营业数据可视化综合看板</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值统计分析</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">推送日报、周报、月报、年报营业数据</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商业智能分析、营业报表分析</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">对比近三年\五年，可设定时间段</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">按商品\类别\员工\包间等多维度</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">进行月\年营收对比分析</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    对海量数据进行分类、抽取、挖掘、得到往期和现在经营的全局视图，商业价值多维画像，图表呈现给管理者，提供决策支持
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">短信平台</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客预订短信</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客存、取茶短信</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">预订、充值扣款余额变化、积分、生日祝福、活动通知、群发管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    系统对接移动、联通、电信三网合一，通知型短信通道(此功能属消耗型，系统提供10条测试短信,如需要继续使用，可以系统上自助购买，根据充值额可有相应赠送，不充值默认为不使用此功能)
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">库存管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">实时库存查询</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">随销售自动出库</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">出入库管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">出入库、报损管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">库存调拨与统计</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">转换整拆零（饼茶撬开成克，按道/壶销售）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">库存盘点：</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">不同销售单位对应不同库存单位不同自动减库量</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">分类别盘点，动态盘点（根据商品设定时间段内有出入变化的，生成盘点任务）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">平库：自动生成盘赢入库、盘亏出库</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">出入库月报表、出入明细，方便库存核对追踪</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">库存预警</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">结存汇总报表（按月\季度\ 年出、入、存汇总）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">出库\入库\调拨 一级审核</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">移动端(微信公众号-小程序)与门店一体化</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    移动工作（管理端）：移动办公，店内数据根据权限随时查看，可自动接收日/周/月/年报表（有开关）
                    （使用 金米茶艺人公众号\金米茶艺人小程序）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">移动工作（员工端）：开单结账、管理自己的会员、查个人业绩，随时进行知识学习
                    （使用 金米茶艺人公众号\金米茶艺人小程序）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值\消费等余额变化微信推送
                    （使用 商户公众号\小程序）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">微信粉丝自动抓取转会员
                    （使用 商户公众号\小程序）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">微信小程序（会员服务）
                    （使用 商户公众号\小程序）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">自助充值</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">自助扫码点单</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">自助查询消费/充值记录，余额对账</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">包间预订</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">存茶、茶杯等数据清晰明了</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">接收预订、消费记录、订单、代金券、礼品卡等的账户余额和业务变化通知</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">线上商城</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">商户公众号\小程序</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">商城后台</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">茶会发布</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">课程发布</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">
                    系统自动记录浏览顾客的手机号、微信头像、昵称、浏览时间、浏览次数信息，为门店引流提供数据
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">设置：5张门店轮播广告图，门店特色介绍</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">设置：商品类别图、类别顺序、上下架</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">设置：商品定价/上下架、推荐、热销、售罄</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">
                    设置商品的详细介绍，最多支持22张图片（含1张推荐大图、1张主图、3张轮播、17张详情图）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">
                    订单审核、发货(自动减库存)管理，快递公司选择，自动推送订单状态消息给顾客
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">顾客发票管理</div>
                  <check></check>
                </div>

                <div class="lv5aU">
                  <div class="kYjpq secondTittle">微信小程序商城（顾客端）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">主页：显示门店地点、营业时间、营业状态、距离、门店导航、联系门店、</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">
                    主页：显示门店地点、营业时间、营业状态、距离、门店导航、联系门店、商品搜索、门店5张广告轮播图、推荐商品、热销商品
                  </div>
                  <check></check>
                </div>

                <div class="lv5aU">
                  <div class="kYjpq important_1">商品显示会员价，下单可明显显示会员优惠，充值会员可享受与店内同等折扣。
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">结算主推储值卡扣款，实现从访客到充值会员的引流</div>
                  <check></check>
                </div>

                <div class="lv5aU">
                  <div class="kYjpq important_1">商城订单支持快递发货和到店自取</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">商城订单可申请发票</div>
                  <check></check>
                </div>

                <div class="lv5aU">
                  <div class="kYjpq important_1">线上代金劵发放</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">礼品卡销售兑换（类似大闸蟹卡）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">采购和供应商管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">供应商档案管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">采购预付款管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">入库单付款管理(使用预付款，多批次付款)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">采购报表分析，预付款、已付款、应付款统计</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">批发和代理商管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">代理商档案管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">预收代理商定金</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">批发销售单管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">批发单付款(使用预订款、多批次付款)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">批发报表统计，批发营业报表、预收款、应收款统计</div>
                  <check></check>
                </div>

                <div class="lv5aU">
                  <div class="secondTittle">服务</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    客服专员+技术经理+运营经理 7*24h
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">多组织架构</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">支持单店、总部、大区、分公司、直营店、代理商、加盟店等多组织架构</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">增值服务（年度）</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">门店发起拼团购</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    免费对接微信、支付宝收款，支持顾客扫二维码、扫顾客付款码、会员自助充值、公众号商城付款、客人自助结账付款、信用卡、储蓄卡刷卡等
                  </div>
                  <check></check>
                </div>
              </div>
            </div>
            <div class="zhankai">
              <button type="button" class="zhankaiButton" @click="zhankai()" v-show="!boxshow">展开</button>
              <button type="button" class="zhankaiButton" @click="zhankai()" v-show="boxshow"
                      v-smooth-scroll="{ duration: 1000,offset: -150 }" href="#target_1">收起
              </button>
            </div>
          </div>
        </div>
        <div class="pageGroup_div" v-show="squareIndex === 5">
          <div>
            <div class="skills-subsection-div" :style="!boxshow?'height:800px':''">
              <div class="r1qtA">
                <div class="_2DmsI">至尊版</div>
                <div class="_1L8tA">
                  适合品牌茶楼、茶企、茶艺培训机构。旗舰版增加了茶会管理、课程管理、预售、团购活动和运营辅导等多项功能，在全面数字化的基础上更好地进行品牌推广
                </div>
              </div>
              <div class="d-flex">
                <button type="button" class="_2RocW" @click="toContact('system')">立即联系</button>
              </div>
              <div class="_2Dxys">
                <div class="secondTittle">基础功能：</div>
                <div class="lv5aU">
                  <div class="_2RocW">员工管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">角色权限</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商品类别</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商品档案</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">套餐设置</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">包间桌台</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">自动计费管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商品会员价</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">仓库\多店异地仓管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">营业管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">预定、开单、增单、赠送、续茶、退单、转台、合并、结账、反结账、挂账、免单</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">点单修改泡茶自动出库量</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">点单时修改出库仓库</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">点单时可多选销售员</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客存茶、取茶(存取量消息通知)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客存取主人杯(物)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客开卡、充值、改密、完善档案等常规管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员私密画像填报</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">吧台支出</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">交接班</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">后厨、水吧打印</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">会员管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员级别(商品折扣率、包间折扣率)
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值档次赠送(可赠送金额、积分、代金券)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员累计消费升级</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员档案管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员爱好公开属性</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员画像私密参数设置</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">3个月(可设置)未消费会员，唤醒消费</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">3个月(可设置)有消费客户，回馈消费</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员消费积分，积分兑换</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员生日提醒</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员生日当天消费多倍积分(可配置)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">线上粉丝抓取转会员</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员代金券</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">消费频次、充值额、消费额排行、末次充值和末次消费时间的统计等</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">消费明细、余额对账</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">财务管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">员工业绩提成报表</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">销售提成:可分服务费提成，商品提成</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值提成：可分首次/非首次充值</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">同一个销售单或商品多员工提成</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">成本和利润管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">财务日/周/月/年报表</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">财务支出（房租\水电费\工资\管理费用等）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">营业数据可视化综合看板</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值统计分析</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">商业智能分析、营业报表分析</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">对比近三年\五年，可设定时间段</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">按商品\类别\员工\包间等多维度</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">进行月\年营收对比分析</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    对海量数据进行分类、抽取、挖掘、得到往期和现在经营的全局视图，商业价值多维画像，图表呈现给管理者，提供决策支持
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">短信平台</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客预订短信</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客存、取茶短信</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">预订、充值扣款余额变化、积分、生日祝福、活动通知、群发管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    系统对接移动、联通、电信三网合一，通知型短信通道(此功能属消耗型，系统提供10条测试短信,如需要继续使用，可以系统上自助购买，根据充值额可有相应赠送，不充值默认为不使用此功能)
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">库存管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">实时库存查询</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">随销售自动出库</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">出入库、报损管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">库存调拨与统计</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">转换整拆零（饼茶撬开成克，按道/壶销售）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">库存盘点：</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">不同销售单位对应不同库存单位不同自动减库量</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">分类别盘点，动态盘点（根据商品设定时间段内有出入变化的，生成盘点任务）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">平库：自动生成盘赢入库、盘亏出库</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">出入库月报表、出入明细，方便库存核对追踪</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">库存预警</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">结存汇总报表（按月\季度\ 年出、入、存汇总）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">出库\入库\调拨一级审核、二级审核</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">移动端(微信公众号-小程序)与门店一体化</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    数据微信推送服务（管理端）：
                    推送日报、周报、月报、年报营业数据
                    开单、结账、充值、反结账等重点业务推送股东
                    （使用 金米茶艺人公众号\金米茶艺人小程序）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">移动工作（员工端）：开单、结账等业务办理，数据统计，管理自己的会员、查个人业绩，随时进行知识学习
                    （使用 金米茶艺人公众号\金米茶艺人小程序）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">会员充值\消费等余额变化微信推送
                    （使用 商户公众号\小程序 ）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">微信粉丝自动抓取转会员
                    （使用 商户公众号\小程序 ）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">微信小程序（会员服务）
                    （使用 商户公众号\小程序 ）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">自助扫码点单</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">自助充值</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">自助查询消费/充值记录，余额对账</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">包间预订</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">存茶、茶杯等数据清晰明了</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">顾客自助结账，散客价格与会员价格优惠对比明显，吸引储值</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">线上商城</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">商户公众号\小程序</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">商城后台</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">茶会发布</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">课程发布</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">
                    系统自动记录浏览顾客的手机号、微信头像、昵称、浏览时间、浏览次数信息，为门店引流提供数据
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">设置：5张门店轮播广告图，门店特色介绍</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">设置：商品类别图、类别顺序、上下架</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">设置：商品定价/上下架、推荐、热销、售罄</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">
                    设置商品的详细介绍，最多支持22张图片（含1张推荐大图、1张主图、3张轮播、17张详情图）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">
                    订单审核、发货(自动减库存)管理，快递公司选择，自动推送订单状态消息给顾客
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">顾客发票管理</div>
                  <check></check>
                </div>

                <div class="lv5aU">
                  <div class="kYjpq secondTittle">微信小程序商城（顾客端）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">主页：显示门店地点、营业时间、营业状态、距离、门店导航、联系门店、</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">
                    主页：显示门店地点、营业时间、营业状态、距离、门店导航、联系门店、商品搜索、门店5张广告轮播图、推荐商品、热销商品
                  </div>
                  <check></check>
                </div>

                <div class="lv5aU">
                  <div class="kYjpq important_1">
                    商品显示会员价，下单可明显显示会员优惠，充值会员可享受与店内同等折扣。
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">结算主推储值卡扣款，实现从访客到充值会员的引流</div>
                  <check></check>
                </div>

                <div class="lv5aU">
                  <div class="kYjpq important_1">商城订单支持快递发货和到店自取</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">商城订单可申请发票</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">线上代金劵发放</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">礼品卡销售兑换（类似大闸蟹卡）</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">课程、茶会管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">课程发布、邀约报名、
                    课程码线下核销、收款等全流程管理（使用 商户公众号\小程序）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">茶会发布、邀约报名、茶会码线下核销、
                    收款等全流程管理（使用 商户公众号\小程序）
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">采购和供应商管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">供应商档案管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">采购预付款管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">入库单付款管理(使用预付款，多批次付款)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">采购报表分析，预付款、已付款、应付款统计</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">批发和代理商管理</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">代理商档案管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">预收代理商定金</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">批发销售单管理</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">批发单付款(使用预订款、多批次付款)</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq ">批发报表统计，批发营业报表、预收款、应收款统计</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">服务</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    客服总监+技术总监+运营总监 7*24h
                  </div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">多组织构架</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">支持单店、总部、大区、分公司、直营店、代理商、加盟店等多组织架构</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="secondTittle">增值服务（年度）</div>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq important_1">门店发起拼团购</div>
                  <check></check>
                </div>
                <div class="lv5aU">
                  <div class="kYjpq">
                    免费对接微信、支付宝收款，支持顾客扫二维码、扫顾客付款码、会员自助充值、公众号商城付款、客人自助结账付款、信用卡、储蓄卡刷卡等
                  </div>
                  <check></check>
                </div>
              </div>
            </div>
            <div class="zhankai">
              <button type="button" class="zhankaiButton" @click="zhankai()" v-show="!boxshow">展开</button>
              <button type="button" class="zhankaiButton" @click="zhankai()" v-show="boxshow"
                      v-smooth-scroll="{ duration: 1000,offset: -150 }" href="#target_1">收起
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="phoneContent">
      <div style="text-align: center;font-weight: 900;font-size: 20px;padding-bottom: 20px;">金米茶艺人&nbsp;&nbsp;&nbsp;&nbsp;茶楼茶馆运营服务</div>

      <div class="tabsGroup">
        <ul>
          <li @click="squareTuoGuanMouse(1)" :class="{'tabsGroup_ul_li_active':squareTuoGuanIndex === 1}">标准版</li>
          <li @click="squareTuoGuanMouse(2)" :class="{'tabsGroup_ul_li_active':squareTuoGuanIndex === 2}">专业版</li>
          <li @click="squareTuoGuanMouse(3)" :class="{'tabsGroup_ul_li_active':squareTuoGuanIndex === 3}">旗舰版</li>
          <li @click="squareTuoGuanMouse(4)" :class="{'tabsGroup_ul_li_active':squareTuoGuanIndex === 4}">托管版</li>
          <li @click="squareTuoGuanMouse(5)" :class="{'tabsGroup_ul_li_active':squareTuoGuanIndex === 5}">茶企版</li>
        </ul>
      </div>
      <div class="pageGroup">
        <div class="pageGroup_div" v-show="squareTuoGuanIndex === 1">
          <div class="freeDiv">
            <div class="freeDiv_div" style="display: inline-block">
              <div class="right" style="color: black">
                <div>
                  <span>标准版:</span> 适合有茶师团队，有主营产品，经营目标明确，缺乏系统性专业知识和应用技巧的茶楼茶馆
                </div>
                <div style="text-align: left">
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">茶类基础知识培训
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">冲泡技巧培训
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">销售技巧培训
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pageGroup_div" v-show="squareTuoGuanIndex === 2">
          <div class="freeDiv">
            <div class="freeDiv_div" style="display: inline-block">
              <div class="right" style="color: black">
                <div>
                  <span>专业版:</span> 适合初创成长型、非茶行业投资人(老板是外行)缺乏系统管理经验和运营团队的茶馆茶楼，提供门店管理制度，协助招聘培训茶师和运营团队
                </div>
                <div style="text-align: left">
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">茶类基础知识培训
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">冲泡技巧培训
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">销售技巧培训
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">管理制度培训
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">管理工具的建立和使用(配合管理制度)
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">团队建设
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pageGroup_div" v-show="squareTuoGuanIndex === 3">
          <div class="freeDiv">
            <div class="freeDiv_div" style="display: inline-block">
              <div class="right" style="color: black">
                <div>
                  <span>旗舰版:</span> 适合中大型旗舰门店，想打造品牌影响力及产品标准，老板对门店格调和品牌定位有较高要求的专业品牌茶馆茶楼
                </div>
                <div style="text-align: left">
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">茶类基础知识培训
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">冲泡技巧培训
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">销售技巧培训
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">管理制度培训
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">管理工具的建立和使用(配合管理制度)
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">团队建设
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">产品设计
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">营销推广
                  </div>
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">品牌定位、门店营销、客群分析和管理策略
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pageGroup_div" v-show="squareTuoGuanIndex === 4">
          <div class="freeDiv">
            <div class="freeDiv_div" style="display: inline-block">
              <div class="right" style="color: black">
                <div>
                  <span>托管版:</span> 适合资源型茶馆、茶楼、茶会所，缺乏茶行业或门店经营管理经验或精力，主要实现资源聚集和转化
                </div>
                <div style="text-align: left">
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">门店具备完备的经营条件，且有明确的经营诉求但不想投入过多时间和精力参与到门店日常琐碎的管理过程中;我方可根据门店需求，提供人员团队、销售产品、常规运营等全面合作服务，让您轻松拥有一间规范、舒适、专业的茶馆空间
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pageGroup_div" v-show="squareTuoGuanIndex === 5">
          <div class="freeDiv">
            <div class="freeDiv_div" style="display: inline-block">
              <div class="right" style="color: black">
                <div>
                  <span>茶企版:</span> 适合有系列产品，有渠道或计划建设渠道(直营、加盟)的茶企，提供企业培训和市场运营人员的团队搭建和管理优化方案
                </div>
                <div style="text-align: left">
                  <div>
                    <img class="imgDots" src="../../assets/点.png" alt="">针对具有一定渠道规模的茶企、茶店，我们通过金米管理系统，有效打通总部与渠道的实时关联，再结合茶企品牌产品的市场特性，制定规范一套适合茶企产品的销售术语和模式，提升渠道终端销售能力;同时帮助茶企搭建至少1-2人的专业运营团队，能够长期持续的对终端提供服务和赋能
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="phoneContentBottom r1qtA">
      <div class="_2DmsI" style="text-align: center;color: #2c2c2c;font-weight: 600;font-size: 28px;line-height: 36px;">
        扫码了解详情
      </div>
      <div style="width: 100%;text-align: center;padding: 10px">

        <img src="../../assets/TGYY.png" alt=""
             style="height: 100px; width: 100px;display: inline-block;border-radius: 12px;">
      </div>
      <div class="d-flex" data-v-4711d3a4="">
        <button type="button" class="_2RocW" data-v-4711d3a4="" @click="toContact('yunYing')">立即联系
        </button>
      </div>
    </div>

    <el-dialog class="contactDialog" v-model="dialogTableVisible" width="80%" title="联系我们"
               style="max-width: 800px;min-width: 200px">
      <ContactUsTabs @closeDialog="closeDialog"></ContactUsTabs>
    </el-dialog>
    <el-dialog class="contactDialog" v-model="dialogTableVisible2" top="40px" width="100%" title="联系我们">
      <contact-us-tabs-for-phone @closeDialog="closeDialog2"></contact-us-tabs-for-phone>
    </el-dialog>

  </div>
  <!-- /.skills-company-subsection -->
</template>

<script>
import detailCard from "@/components/PageTools/detailCard";
import ContactUsTabs from "@/components/ContactUs/ContactUsTabs";
import ContactUsTabsForPhone from "@/components/ContactUs/ContactUsTabsForPhone";
import check from "@/components/icon/check";

export default {
  components: {
    detailCard,
    ContactUsTabs,
    ContactUsTabsForPhone,
    check
  },
  props: {
    height: {
      type: Number,
      default: 0
    }
  },
  name: "HomeMainPriceIntroduction",
  data() {
    return {
      screenWidth: 0,
      squareTuoGuanIndex: 1,
      squareIndex: 2,
      boxshow: false,
      dialogTableVisible: false,
      dialogTableVisible2: false,
      tabsName: "system",
      biaozhunbanList: ['基础功能', '员工管理', '角色权限', '商品类别', '商品管理', '套餐设置', '包间桌台', '自动计费管理',
        '商品会员价', '仓库\\多店异地仓管理', '营业管理', '包间自动计时计费',
        '预定、开单、增单、赠送、续茶、退单、转台、合并、结账、反结账、挂账、免单', '点单修改泡茶自动出库量', '点单时修改出库仓库',
        '点单时指定销售员', '顾客存茶、存课程、取茶 (存取量消息通知)', '顾客存取主人杯', '顾客开卡、充值、改密、完善档案等常规管理',
        '会员私密画像填报', '吧台支出', '交接班', '后厨、水吧打印', '会员管理', '会员级别(商品折扣率、包间折扣率)', '会员充值档次赠送(可赠送金额、积分、代金券)', '会员档案管理',
        '会员爱好公开属性', '会员画像私密参数设置', '会员充值消费短信通知', '会员充值消费微信通知', '重点顾客到店推送老板通知', '3个月(可设置)未消费会员，唤醒消费',
        '3个月(可设置)有消费客户，回馈消费', '会员消费积分，积分兑换', '会员生日提醒', '线上粉丝抓取转会员', '会员代金券、礼品卡', '消费频次、充值额、消费额排行、末次充值和末次消费时间的统计等',
        '会员多维度分析、消费明细、余额对账', '财务管理', '员工业绩提成报表', '销售提成：可分服务费提成，商品提成', '会员充值提成：可分首次/非首次充值', '成本和利润管理',
        '财务日/周/月/年报表', '财务支出（房租\\水电费\\工资\\管理费用等）', '销售、排行、挂账、反结报表', '会员充值统计分析', '数据微信推送服务：', '推送日报、周报、月报、年报营业数据',
        '开单、结账、充值、反结账等重点业务推送股东', '商业智能分析、营业报表利润分析', '对比近三年\\五年，可设定时间段', '按商品\\类别\\员工\\包间等多维度',
        '进行月\\年营收对比以及利润贡献分析', '对海量数据进行分类、抽取、挖掘、得到往期和现在经营的全局视图，商业价值多维画像，图表呈现给管理者，提供决策支持。',
        '短信平台', '顾客预订短信', '顾客存、取茶短信', '预订、充值扣款余额变化、积分、生日祝福、活动通知、群发管理', '系统对接移动、联通、电信三网合一，通知型短信通道',
        '此功能是消耗型，系统提供10条测试短信,如需要继续使用，可以系统上自助购买，默认每条0.1元，根据充值额可有相应赠送，不充值默认为不使用此功能',
        '库存管理', '实时库存查询', '随销售自动出库', '出入库管理', '出入库管理、随销售自动出库', '库存调拨与统计', '库存报损与统计', '转换整拆零（饼茶撬开成克，按道/壶销售）',
        '库存盘点：', '不同销售单位对应不同库存单位不同自动减库量', '分类别盘点，动态盘点（根据商品设定时间段内有出入变化的，生成盘点任务）', '平库：自动生成盘赢入库、盘亏出库',
        '出入库月报表、出入明细，方便库存核对追踪', '库存预警', '结存汇总报表（按月\\季度\\ 年出、入、存汇总）', '出库\\入库\\调拨一级审核、二级审核',
        '移动端(微信公众号\\小程序)与门店一体化', '移动工作（管理端）：移动办公，店内数据根据权限随时查看，可自动接收日/周/月/年报表（有开关）',
        '移动工作（员工端）：开单结账、管理自己的会员、查个人业绩，随时进行知识学习', '会员充值\\消费等余额变化微信推送', '微信粉丝自动抓取转会员', '微信小程序（会员服务）',
        '自助充值', '自助查询消费/充值记录，余额对账●包间预订、电子茶单', '接收预订、消费、订单等的账户余额和业务变化通知', '存茶、茶杯等数据清晰明了',
        '散客通过微信付款，首单可享95折(可设置)，即成为本店普通会员', '顾客自助结账，散客价格与会员价格优惠对比明显，吸引储值', '线上商城',
        '商城后台：', '系统自动记录浏览顾客的手机号、微信头像、昵称、浏览时间、浏览次数信息，为门店引流提供数据', '设置：5张门店轮播广告图，门店特色介绍',
        '设置：商品类别图、类别顺序、上下架', '设置：商品定价/上下架、推荐、热销、售罄', '设置商品的详细介绍，最多支持22张图片（含1张推荐大图、1张主图、3张轮播、17张详情图）',
        '订单审核、发货(自动减库存)管理', '快递公司选择', '自动推送订单状态消息给顾客', '顾客发票管理', '采购和供应商管理', '供应商档案管理',
        '采购预付款管理', '入库单付款管理(使用预付款，多批次付款)', '采购报表分析，预付款、已付款、应付款统计', '批发和代理商管理', '代理商档案管理',
        '预收代理商定金', '批发销售单管理', '批发单付款(使用预订款、多批次付款)', '批发报表统计，批发营业报表、预收款、应收款统计', '多组织构架'
        , '支持单店、总部、大区、分公司、直营店、代理商、加盟店等多组织架构', '增值服务（年度）', '茶艺人平台引流', '免费对接微信、支付宝收款，支持顾客扫二维码、扫顾客付款码、会员自助充值、公众号商城付款、客人自助结账付款、信用卡、储蓄卡刷卡等'
      ]
    }
  },
  mounted() {
    window.addEventListener('resize', this.screenType);
    this.screenWidth = document.body.offsetWidth;
  },
  unmounted() {
    window.removeEventListener('resize', this.screenType)
  },
  methods: {
    screenType() {
      this.screenWidth = document.body.offsetWidth;
    },
    zhankai() {
      this.boxshow = !this.boxshow
    },
    closeDialog() {
      this.dialogTableVisible = false;
    },
    closeDialog2() {
      this.dialogTableVisible2 = false;
    },
    toContact(val) {
      if (this.screenWidth < 500) {
        this.dialogTableVisible2 = true;
      } else {
        this.dialogTableVisible = true;
      }
    },
    squareMouse(index) {
      this.squareIndex = index;
    },
    squareTuoGuanMouse(index) {
      this.squareTuoGuanIndex = index;
    }
  }
}
</script>

<style scoped>
.freeDiv {
  width: 100%;
  min-height: 260px;
  display: flex;
  justify-content: center;
}

.freeDiv_div .r1qtA ._2DmsI {
  text-align: center;
  color: #2c2c2c;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
}

.freeDiv_div .r1qtA {
  width: 30%;
  display: inline-block;
  height: 100%;
  min-height: 200px;
  vertical-align: top;
  padding: 10px 0
}

.freeDiv_div .right {
  display: inline-block;
  width: 70%;
  height: 100%;
  min-height: 200px;
  vertical-align: top;
  padding: 10px 0
}

.freeDiv_div .r1qtA ._1L8tA {
  font-size: 14px;
  line-height: 20px;
  color: #6e6e6e;
  display: inline-block;
  text-indent: 2em;
}

.freeDiv_div .right div {
  margin-top: 0;
}

.freeDiv_div .right div span {
  font-weight: 900;
  font-size: 16px;
}

/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {
  .contactDialog {
    width: 100%;
  }

  .freeDiv_div .r1qtA ._2DmsI {
    text-align: left;
    color: #2c2c2c;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
  }

  .freeDiv_div .r1qtA ._1L8tA {
    font-size: 14px;
    line-height: 20px;
    color: #6e6e6e;
    display: inline-block;
    text-indent: 0;
  }

  .pageGroup .pageGroup_div {
    animation: animated-border 1.5s infinite;
    font-size: 14px;
    line-height: 30px;
    color: #000000;
  }

  .skills-subsection-div {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);
    border-radius: 8px;
    overflow: hidden;
    padding: 2px;
    margin-top: 0;
    transition: height 0.6s ease;
  }

  .skills-subsection-topDiv {

  }

  .freeDiv_div {
    height: 100%;
    min-height: 250px;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
    margin-bottom: 10px;
    text-align: left;
  }

  .freeDiv_div .r1qtA {
    width: 100%;
    display: inline-block;
    height: auto;
    min-height: 0;
    vertical-align: top;
    padding: 0;
  }

  .freeDiv_div .right {
    display: inline-block;
    width: 100%;
    height: 100%;
    min-height: 0;
    vertical-align: top;
    padding: 10px 0;
    text-align: center;
  }

  .phoneContent {
    display: block;
    color: #000000;
    padding-top: 10px;
    padding-bottom: 0;
  }

  .tabsGroup {
    width: 100%;
    height: 40px;
    /*background: #cccccc;*/
  }

  .pageGroup .pageGroup_div[data-v-4711d3a4] {
    height: auto;
    display: inline-block;
    margin: 3px;
    min-height: 300px;
    white-space: pre-line;
    font-size: 15px;
    display: -webkit-inline-flex;
    text-align: left;
  }

  .pageGroup .pageGroup_div {
    animation: animated-border 1.5s infinite;
    font-size: 14px;
    line-height: 30px;
    color: #000000;
    border-radius: 10px;
    padding: 15px;
  }


  .phoneContent {
    display: block;
    color: #000000;
    padding-top: 10px;
    padding-bottom: 0;
  }


  .freeDiv_div .right div {
    margin-top: 0;
  }

  .tabsGroup {
    width: 100%;
    height: 40px;
    /*background: #cccccc;*/
  }

  .tabsGroup ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }

  .tabsGroup ul li {
    display: inline-block;
    width: 20%;
    height: 100%;
    text-align: center;
    padding: 10px;
    font-size: 16px;
  }

  .tabsGroup_ul_li_active {
    color: #57b05b;
    border-top: 2px solid #57b05b;
  }

  .container {
    display: none;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 768px) and (max-width: 992px) {
  .skills-subsection-topDiv {
    display: flex;
    justify-content: center
  }

  .phoneContent {
    display: none;
  }

  .phoneContentBottom {
    display: none;
  }

  .freeDiv_div {
    height: 100%;
    min-height: 250px;
    width: 61%;
    background: #fff;
    box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
    margin-bottom: 10px;
    text-align: left;
    min-width: 900px;
  }

  .group_dot {
    color: #999999;
    padding-left: 50px;
  }

  .imgDots {
    width: 25px;
    height: 25px;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 992px) {
  .skills-subsection-topDiv {
    display: flex;
    justify-content: center
  }

  .phoneContent {
    display: none;
  }

  .phoneContentBottom {
    display: none;
  }

  .freeDiv_div {
    height: 100%;
    min-height: 250px;
    width: 61%;
    background: #fff;
    box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
    margin-bottom: 10px;
    text-align: left;
    min-width: 900px;
  }
}

.group_dot {
  color: #999999;
  font-size: 14px;
  padding-left: 50px;
}

.imgDots {
  width: 25px;
  height: 25px;
}

.important_1 {
  color: #ff3331;
  /*background-color: rgb(245, 225, 208)*/
  font-weight: bold;
  font-size: 16px;
}

</style>
