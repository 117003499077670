<template>
  <div>
    <div style="">
      <el-form ref="form" :model="form" label-width="110px" style="min-width: 80%">
        <div style="padding-left: 20px;margin: 0 0 20px 0">
          <el-alert
              title="请填写您的信息，我们将很快与您取得联系，也可点击下方按钮，直接联系客服哦"
              type="success"
              :closable="false">
          </el-alert>
        </div>
        <el-form-item label="联系人">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="您感兴趣的内容">
          <el-checkbox-group v-model="form.type">
            <el-checkbox label="数字化系统" name="type"></el-checkbox>
            <el-checkbox label="人才培训/招聘" name="type"></el-checkbox>
            <el-checkbox label="托管运营" name="type"></el-checkbox>
            <el-checkbox label="茶馆集采" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="您的茶馆/茶企目前的状态">
          <el-input type="textarea" v-model="form.beiZhu" placeholder="请简要描述您的需求和目前经营状态（30字以内）"
                    maxlength="30"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="onSubmit" style="min-width: 100px;margin-left: auto">提交</el-button>
          <!--          <el-button @click="cancel">取消</el-button>-->
        </el-form-item>
        <a style="margin-left: 32%;min-width: 200px;min-height: 80px"
           href="https://work.weixin.qq.com/kfid/kfc9a28e09b78cc87de">
          <el-button round type="success" style="min-height: 40px;margin:20px 0 20px 0">
            <el-icon :size="20">
              <Service></Service>
            </el-icon>
            &nbsp; &nbsp;立即联系客服
          </el-button>
        </a>
      </el-form>
<!--      <img-->
<!--          style="max-width:30%;margin-left: 20%"-->
<!--          src="../../assets/联系我们-客服.png" alt="">-->
<!--      <img-->
<!--          style="max-width:30%;margin-left: 5%"-->
<!--          src="../../assets/联系我们-公众号.png" alt="">-->
    </div>
  </div>
</template>
<script>

import axios from "axios";

export default {
  props: {
    tabsName: {
      type: String,
      default: "tuoGuang"
    },
  },
  name: "ContactUsTabsForPhone",
  data() {
    return {
      screenWidth: 0,
      disabledButton: false,
      req: Object,
      btnText: "获取短信验证码",
      form: {
        name: '',
        phone: '',
        type: [],
        beiZhu: '',
        dateTime: '',
      },
      gettime: '',
      ruleForm: {
        name: '',//联系人姓名
        phone: '',//联系人手机号
        code: '',//验证码
      },
    };
  },
  methods: {
    // ContactNow(){
    //  
    // },
    onSubmit() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
      this.form.dateTime = this.gettime;
      console.log(this.form);
      //手机号校验
      let reg = /^1[3456789]\d{9}$/
      //校验各项不为空
      if (this.form.name === '' || this.form.name === null || this.form.name === undefined) {
        this.$message.error('联系人不能为空');
        return;
      } else if (this.form.name.length > 10){
        this.$message.error('联系人名称过长');
        return;
      }else if (reg.test(this.ruleForm.phone)) {
        this.$message.error('请输入正确的手机号');
        return;
      } else if (this.form.beiZhu.length > 30) {
        this.$message.warning('备注信息过长');
        return;
      }
      //请求接口
      axios.post('https://cyrapi.jmtcsoft.com/api/NewCustomer/Create', {
        // axios.post('http://localhost:5000/api/NewCustomer/Create', {
        name: this.form.name,
        phone: this.form.phone,
        systemYiXiang: this.form.type.includes('数字化系统'),
        tuoGuanYiXiang: this.form.type.includes('托管运营'),
        heZuoYiXiang: this.form.type.includes('战略合作'),
        renCaiYiXiang: this.form.type.includes('人才培训/招聘'),
        jiCaiYiXiang: this.form.type.includes('茶馆集采'),
        beiZhu: this.form.beiZhu,
        tiJiaoDate: this.form.dateTime,
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message.success({
            message: '提交成功，我们将于3个工作日内联系您',
            duration: 5000,
            showClose: true,
          });
          //关闭弹窗
          this.$emit('closeDialog');
        } else {
          this.$message.warning(res.data.msg);
        }
      })
    },
    //选中
    tabClick(v) {
    },
    //提交
    submitForm() {
      let that = this;
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //获取验证码
    getCode() {
      //手机号校验
      let reg = /^1[3456789]\d{9}$/
      let that = this;
      let data = {
        txt_Mobile: that.ruleForm.phone
      }
      if (!reg.test(that.ruleForm.phone)) {
        this.$message.error('手机号校验失败！');
        return false
      } else {
        this.btnText = "重新发送(60)";
        this.disabledButton = true;
        //计时器
        this.codeInterval();
        // axios.get('https://chayiren.jmtcsoft.com/QTNoToken/SendMobileCheckMsg?phone='+that.ruleForm.phone)
      }
    },
    cancel() {
      this.$emit('closeDialog');
    },
    codeInterval() {
      const TIME_COUNT = 59;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.btnText = "重新发送(" + this.count-- + ")";
          } else {
            clearInterval(this.timer);
            this.timer = null;
            this.disabledButton = false;
            this.btnText = "获取验证码";
          }
        }, 1000);
      }
    },
  }
}

</script>

<style scoped>

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
