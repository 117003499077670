<template>
  <div class="row">
    <div class="col-md-8 col-md-offset-2">
      <div class="title-content">
        <h2 class="section-title">1351感动式服务</h2>
      </div>
    </div>
  </div>
  <div style="color: black;padding-bottom: 20px">
    <div class="bottom_1351" style="width: 80%;margin: 0 auto;text-align: center">
      <ul>
        <li>
          <img src="../../assets/fuwu/信息.png" alt="">
          <div>
            <span style="font-size: 18px;font-weight: 800">1分钟</span> 联系客服
          </div>
        </li>
        <li>
          <img src="../../assets/fuwu/锁定.png" alt="">
          <div><span style="font-size: 18px;font-weight: 800">3分钟</span> 定位问题</div>
        </li>
        <li>
          <img src="../../assets/fuwu/成功.png" alt="">
          <div><span style="font-size: 18px;font-weight: 800">5分钟</span> 解决问题</div>
        </li>
        <li>
          <img src="../../assets/fuwu/24.png" alt="">
          <div><span style="font-size: 18px;font-weight: 800">1天内</span> 发布新版</div>
        </li>
      </ul>

      <ul>
        <li>
          <img src="../../assets/fuwu/class.png" alt="">
          <div>
            <span style="font-size: 18px;font-weight: 800">定期线上专题培训</span>
          </div>
        </li>
      </ul>

<!--      <div>-->
<!--        <img src="../../assets/fuwu/重点标注.png" alt="" style="width: 30px;display: inline-block;vertical-align: middle">-->
<!--        <span style="font-weight: bold;font-size:16px;">定期线上专题培训。</span>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeMain1351"
}
</script>

<style scoped>

/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {
  .top_1351 div:first-child {
    font-size: 35px;
  }

  .top_1351_line {
    width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid #000000;
    padding-top: 15px;
  }

  .top_1351 div span {
    color: #e4b118;
  }

  .top_1351 div:last-child {
    font-size: 16px;
    padding-top: 15px;
  }

  .bottom_1351 ul {
    width: 100%;
  }

  .bottom_1351 ul li {
    width: 50%;
    display: inline-block;
    margin: 20px 0 0;
  }

  .bottom_1351 ul li img {
    margin-bottom: 24px;
    width: 50px;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 768px) and (max-width: 992px) {

  .top_1351 div:first-child {
    font-size: 35px;
  }

  .top_1351_line {
    width: 20%;
    margin: 0 auto;
    border-bottom: 1px solid #000000;
    padding-top: 15px;
  }

  .top_1351 div span {
    color: #e4b118;
  }

  .top_1351 div:last-child {
    font-size: 16px;
    padding-top: 15px;
  }

  .bottom_1351 {

  }

  .bottom_1351 ul {
    height: 150px;
    margin: 40px auto;
  }

  .bottom_1351 ul li {
    display: inline-block;
    width: calc(100% / 4);
    height: 100%;
    border: 4px solid transparent;
    border-radius: 50%;
    transition: all .6s;
  }

  .bottom_1351 ul li img {
    margin-bottom: 24px;
    width: 100px;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 992px) {

  .top_1351 div:first-child {
    font-size: 35px;
  }

  .top_1351_line {
    width: 20%;
    margin: 0 auto;
    border-bottom: 1px solid #000000;
    padding-top: 15px;
  }

  .top_1351 div span {
    color: #e4b118;
  }

  .top_1351 div:last-child {
    font-size: 16px;
    padding-top: 15px;
  }

  .bottom_1351 {

  }

  .bottom_1351 ul {
    width: 50%;
    height: 150px;
    margin: 40px auto;
  }

  .bottom_1351 ul li {
    display: inline-block;
    width: calc(100% / 4);
    height: 100%;
    border: 4px solid transparent;
    border-radius: 50%;
    transition: all .6s;
  }

  .bottom_1351 ul li img {
    margin-bottom: 24px;
    width: 100px;
  }
}

h2.section-title:after, .h2.section-title:after {
  display: block;
  content: '';
  width: 100%;
  height: 13px;
  /* background: url(../img/svg/separator.svg) center center no-repeat; */
  margin-top: 0;
}
</style>
