<template>
  <div class="home">
    <!--  头部  -->
    <home-header></home-header>
    <!--  内容区域  -->
    <!--  客户案例  -->
    <main>
      <section id="portfolio">
        <home-main-portfolio></home-main-portfolio>
      </section>
    </main>
    <!--  底部  -->
    <home-footer></home-footer>
  </div>
</template>

<script>
import homeHeader from "@/components/HomeHeader";
import homeFooter from "@/components/HomeFooter";
import homeMainPortfolio from "@/components/HomeMain/HomeMainPortfolio";

export default {
  name: "customerView",
  components: {
    homeHeader,
    homeFooter,
    homeMainPortfolio
  }

}
</script>

<style scoped>
section {
  background: #fafafa;
}
</style>