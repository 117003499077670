<template>
  <div>

    <HomeHeader></HomeHeader>
    <div class="container" style="padding: 91px 0 0;">

      <div class="row">

        <div class="col-md-8 col-md-offset-2">

          <div class="title-content">

            <h2 class="section-title">客户案例</h2>


          </div>

        </div>

      </div> <!-- /.row -->

      <div class="row">

        <nav id="portfolio-navigation" class="navbar">

          <!-- Section title -->
          <h1 class="sr-only">Portfolio navigation</h1>

          <ul class="nav navbar-nav">
            <li>
              <button class="filter mixitup-control-active" type="button" data-mixitup-control
                      data-filter="all">全部
              </button>
            </li>
            <!--                        <li>-->
            <!--                            <button class="filter" type="button" data-mixitup-control data-filter=".web_design">Web-->
            <!--                                design-->
            <!--                            </button>-->
            <!--                        </li>-->
            <!--                        <li>-->
            <!--                            <button class="filter" type="button" data-mixitup-control data-filter=".graphic_design">-->
            <!--                                Graphic design-->
            <!--                            </button>-->
            <!--                        </li>-->
            <!--                        <li>-->
            <!--                            <button class="filter" type="button" data-mixitup-control data-filter=".photography">-->
            <!--                                Photography-->
            <!--                            </button>-->
            <!--                        </li>-->
            <!--                        <li>-->
            <!--                            <button class="filter" type="button" data-mixitup-control data-filter=".apps">Apps</button>-->
            <!--                        </li>-->
          </ul>

        </nav> <!-- /#portfolio-navigation -->

      </div> <!-- /.row -->

      <div class="row">

        <div id="portfolio-grid" class="portfolio-grid">

          <figure class="grid-item mix all graphic_design">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%8C%97%E4%BA%AC%E5%A4%A7%E7%9B%8A%E8%8C%B6%E6%80%BB%E9%83%A8.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all web_design">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E6%BE%9C%E6%B2%A7%E5%8F%A4%E8%8C%B6.jpg"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all apps">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E9%9C%B2%E9%9B%A8%E8%BD%A9.jpg"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%8F%A4%E4%B9%90%E8%8C%97%E8%BD%A9.jpg"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all web_design">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%85%AB%E9%A9%AC%E8%8C%B6%E4%B8%9A.jpg"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all apps">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E8%8C%B6%E4%B8%80%E9%B9%AD%E6%B9%96%E9%87%8C.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all graphic_design">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E6%BE%84%E5%9B%AD%E8%8C%B6%E5%9D%8A.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all web_design">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E9%9B%A8%E6%9E%97%E5%8F%A4%E6%A0%91%E8%8C%B6.jpg"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E7%8E%96%E9%91%AB%E4%BB%BF%E5%8F%A4.jpg"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E8%90%83%E8%BE%B0%E5%A4%A9%E5%BF%83%E6%B5%B7%E5%8F%A3.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src='http://www2.jmtcsoft.com/img/MenTou/%E5%A4%A7%E5%AE%8B%E8%8C%B6%E9%99%A2.png'
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src='http://www2.jmtcsoft.com/img/MenTou/%E8%A7%82%E6%AD%A2%E8%8C%B6%E9%9A%90.jpg'
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E9%9D%99%E5%B1%85%E9%9B%85%E8%88%8D.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E4%B9%9D%E6%80%9D%E8%8C%B6%E4%B9%A6%E9%99%A2.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E4%B8%89%E8%8C%B6%E4%BA%94%E5%93%81.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E6%B0%B4%E4%BA%91%E9%97%B4.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E4%BA%AC%E5%8D%8E%E8%8C%B6%E8%8B%91.jpg"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%A4%A9%E5%92%A8%E9%9B%A8.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%90%8C%E6%B3%B0%E8%8C%B6%E8%89%BA%E9%A6%86.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%AE%A4%E5%86%85%E9%AB%98%E5%B0%94%E5%A4%AB.jpg"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E6%AD%A6%E6%B1%89%E7%AB%A0%E6%A1%90%E4%B9%A6%E9%99%A2.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E9%A6%99%E6%A0%BC%E9%87%8C.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E6%AD%A3%E5%B1%B1%E5%A0%82.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E8%8C%B6%E7%BC%98%E5%A0%82.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E7%A6%8F%E4%BB%8A%E8%8C%B6%E4%B8%9A.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%8F%A4%E9%81%93%E9%97%AE%E8%8C%B6.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%92%8C%E4%B9%90%E8%BD%A9.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E8%A7%82%E5%91%B3%E8%8C%B6%E9%A6%99.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E6%B6%B5%E5%B1%B1%E8%88%8D.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%8D%8E%E4%BD%97%E5%85%BB%E7%94%9F%E5%A1%98.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E4%BD%B3%E6%B2%83%E8%8C%B6%E7%A4%BE.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%98%89%E6%9C%A8%E5%A0%82%E8%8C%B6%E6%A5%BC.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E4%BA%AC%E5%B8%88%E8%8C%B6%E8%8B%91.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%90%8C%E5%BA%86%E5%8F%B7.jpg"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E8%8C%97%E7%A6%8F%E5%B1%85%E8%8C%B6%E5%BA%84.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg" src="http://www2.jmtcsoft.com/img/MenTou/%E5%93%81%E8%87%BB.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%B0%9A%E9%9B%A8%E8%BD%A9.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%8D%87%E6%A0%BC%E6%A5%BC.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E6%8B%BE%E5%B9%B4%E8%8C%B6%E9%A6%86%E6%B5%8E%E5%8D%97%E5%BA%97.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E6%B3%B0%E5%B7%9E%E4%BC%9A%E9%A6%86.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E9%98%B3%E5%85%89%E8%8C%97%E5%9B%AD%E8%8C%B6%E8%89%BA%E9%A6%86.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E7%9F%A5%E5%91%B3%E8%8C%B6%E9%A6%86.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E7%9F%A5%E9%97%B2%E8%8C%B6%E8%8B%91.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E7%B4%AB%E6%BA%90%E8%BD%A9.png"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="grid-item mix all photography">
            <img class="menTouImg"
                 src="http://www2.jmtcsoft.com/img/MenTou/%E5%84%92%E5%A3%AB%E9%A6%86.jpg"
                 alt="Placeholder portfolio image">
          </figure>

          <figure class="gap"></figure>

        </div>

      </div> <!-- /.row -->

    </div> <!-- /.container -->

    <HomeFooter></HomeFooter>
  </div>
</template>

<script>
import HomeHeader from "@/components/HomeHeader";
import HomeFooter from "@/components/HomeFooter";


export default {
  name: "HomeMainPortfolio",
  components: {HomeHeader, HomeFooter}

}
</script>

<style scoped>

</style>
