<template>
  <div class="row">
    <div class="title-content">
      <h2 class="section-title">用户门店靓照</h2>
    </div>
  </div>

  <div class="row" v-show="screenWidth>=758">
    <div class="el_carousel_popupTop">
      <el-carousel indicator-position="outside" :interval="3000" :height="Number(240*3) + 'px'">
        <el-carousel-item v-for="page in pageNum" :key="page" style="height: 100% !important;">

          <div style="text-align: center" class="popupCard">
            <div v-for="(item,index) in list1" :key="index" v-if="page === 1"
                 style="display: inline-block;width: 350px;min-width: 350px;min-height: 240px;height: auto;margin: 2px">
              <img :src="require('../../assets/MenTou/'+item.url)" :alt="item.url">
            </div>
          </div>

          <div style="text-align: center" class="popupCard">
            <div v-for="(item,index) in list2" :key="index" v-if="page === 2"
                 style="display: inline-block;width: 350px;min-width: 350px;min-height: 240px;height: auto;margin: 2px">
              <img :src="require('../../assets/MenTou/'+item.url)" :alt="item.url">
            </div>
          </div>

          <div style="text-align: center" class="popupCard">
            <div v-for="(item,index) in list3" :key="index" v-if="page === 3"
                 style="display: inline-block;width: 350px;min-width: 350px;min-height: 240px;height: auto;margin: 2px">
              <img :src="require('../../assets/MenTou/'+item.url)" :alt="item.url">
            </div>
          </div>

          <div style="text-align: center" class="popupCard">
            <div v-for="(item,index) in list4" :key="index" v-if="page === 4"
                 style="display: inline-block;width: 350px;min-width: 350px;min-height: 240px;height: auto;margin: 2px">
              <img :src="require('../../assets/MenTou/'+item.url)" :alt="item.url">
            </div>
          </div>

          <div style="text-align: center" class="popupCard">
            <div v-for="(item,index) in list5" :key="index" v-if="page === 5"
                 style="display: inline-block;width: 350px;min-width: 350px;min-height: 240px;height: auto;margin: 2px">
              <img :src="require('../../assets/MenTou/'+item.url)" :alt="item.url">
            </div>
          </div>

          <div style="text-align: center" class="popupCard">
            <div v-for="(item,index) in list6" :key="index" v-if="page === 6"
                 style="display: inline-block;width: 350px;min-width: 350px;min-height: 240px;height: auto;margin: 2px">
              <img :src="require('../../assets/MenTou/'+item.url)" :alt="item.url">
            </div>
          </div>

          <div style="text-align: center" class="popupCard">
            <div v-for="(item,index) in list7" :key="index" v-if="page === 7"
                 style="display: inline-block;width: 350px;min-width: 350px;min-height: 240px;height: auto;margin: 2px">
              <img :src="require('../../assets/MenTou/'+item.url)" :alt="item.url">
            </div>
          </div>

          <div style="text-align: center" class="popupCard">
            <div v-for="(item,index) in list8" :key="index" v-if="page === 7"
                 style="display: inline-block;width: 350px;min-width: 350px;min-height: 240px;height: auto;margin: 2px">
              <img :src="require('../../assets/MenTou/'+item.url)" :alt="item.url">
            </div>
          </div>

          <div style="text-align: center" class="popupCard">
            <div v-for="(item,index) in list9" :key="index" v-if="page === 7"
                 style="display: inline-block;width: 350px;min-width: 350px;min-height: 240px;height: auto;margin: 2px">
              <img :src="require('../../assets/MenTou/'+item.url)" :alt="item.url">
            </div>
          </div>
          <div style="text-align: center" class="popupCard">
            <div v-for="(item,index) in list10" :key="index" v-if="page === 7"
                 style="display: inline-block;width: 350px;min-width: 350px;min-height: 240px;height: auto;margin: 2px">
              <img :src="require('../../assets/MenTou/'+item.url)" :alt="item.url">
            </div>
          </div>


        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="el_carousel_popup">
      <div class="phonePopup">
        <div v-for="(item,index) in dataList" :key="index"
             style="display: inline-block;width: calc(100% / 3 - 12px);min-height: 100px;height: auto;margin: 2px 0 0">
          <img :src="require('../../assets/MenTou/'+item.url)" :alt="item.url" style="width: 100%">
        </div>
      </div>
    </div>
  </div>

  <div v-show="screenWidth<758">
    <el-carousel indicator-position="none" :interval="3000">
      <el-carousel-item v-for="item in dataList" :key="item" style="height: 100% !important;">
        <div style="text-align: center" class="popupCard">
            <img :src="require('../../assets/MenTou/'+item.url)" :alt="item.url">
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: 'HomeMainPortfolio',
  props: {
    isShowHeader: {
      type: Boolean,
      default: false,
    },
    bIsMobile: false,
  },
  data() {
    return {
      picNum:67,//当前照片数量
      screenWidth: 0,
      pageNum: 0,
      pageCount: 0,
      list1:[],
      list2:[],
      list3:[],
      list4:[],
      list5:[],
      list6:[],
      list7:[],
      list8:[],
      list9:[],
      list10:[],
      dataList: [
        {url: "北京大益茶总部.png"},
        {url: "2大益.png"},
        {url: "2大益 (1).png"},
        {url: "4中茶.png"},
        {url: "5雨林.png"},
        {url: "5雨林深圳.png"},
        {url: "6福今茶业.png"},
        {url: "7古乐茗轩.png"},
        {url: "8露雨轩.png"},
        {url: "8雨林古树茶.png"},
        {url: "9正山堂.png"},
        {url: "10八马.png"},
        {url: "11嘉木堂茶楼.png"},
        {url: "12中国茶叶.png"},
        {url: "13雨林古树茶.png"},
        {url: "14一品堂.png"},
        {url: "15圣仙亭.png"},
        {url: "16八马茶业.png"},
        {url: "17京师.png"},
        {url: "19八马.png"},
        {url: "19澜沧古茶.png"},
        {url: "21风雅颂.png"},
        {url: "21无双.png"},
        {url: "23古乐茗轩1.png"},
        {url: "24萃辰天心1.png"},
        {url: "25大宋茶院.png"},
        {url: "27九思.png"},
        {url: "28紫源轩1.png"},
        {url: "29古乐茗轩.png"},
        {url: "30同泰茶艺馆.png"},
        {url: "31观止茶隐.png"},
        {url: "32同庆号.png"},
        {url: "33武汉章桐书院.png"},
        {url: "34古御林.png"},
        {url: "35茗福居茶艺.png"},
        {url: "36雨林古树茶.png"},
        {url: "37昔归茶苑.png"},
        {url: "38茶缘堂.png"},
        {url: "39双陈.png"},
        {url: "40雨林.png"},
        {url: "41雨林.png"},
        {url: "42茶一鹭湖里.png"},
        {url: "43澄园茶坊.png"},
        {url: "44古道问茶.png"},
        {url: "45涵山舍.png"},
        {url: "46佳沃茶社.png"},
        {url: "47静居雅舍.png"},
        {url: "48玖鑫仿古.png"},
        {url: "49品臻.png"},
        {url: "50儒士馆.png"},
        {url: "51尚雨轩.png"},
        {url: "52升格楼.png"},
        {url: "53水云间.png"},
        {url: "54泰州会馆 .png"},
        {url: "55汲壶.png"},
        {url: "56三茶五品.png"},
        {url: "57峟茶.png"},
        {url: "58一积.png"},
        {url: "59雨林.png"},
        {url: "60雨林.png"},
        {url: "61大益茶.png"},
        {url: "62景和园.png"},
        {url: "63竹语苔.png"},
        {url: "64香格里.png"},
        {url: "65知未.png"},
      ],
      dataList2: [
        // 主体数据结束
        {url: "观之茶隐.png"},
        {url: "大宋茶院.png"},
        {url: "萃辰天心书院.png"},
        {url: "澄园茶坊.png"},
        {url: "古乐茗轩.png"},
        {url: "八马茶业.png"},
        {url: "大益中心.png"},
        {url: "福今茶业.png"},
        {url: "茗福居.png"},
        {url: "涵山舍.png"},
        {url: "和乐轩.png"},
        {url: "养生塘.png"},
        {url: "京华茶苑.png"},
        {url: "儒士馆.png"},
        {url: "mmexport1644135881973.jpg"},
        {url: "mmexport1644331307165.jpg"},
        {url: "mmexport1644331349969.jpg"},
        {url: "QQ截图20220224101702.jpg"},
        //填充
        // 主体数据结束
        {url: "观之茶隐.png"},
        {url: "大宋茶院.png"},
        {url: "萃辰天心书院.png"},
        {url: "澄园茶坊.png"},
        {url: "古乐茗轩.png"},
        {url: "八马茶业.png"},
        {url: "大益中心.png"},
        {url: "福今茶业.png"},
        {url: "茗福居.png"},
        {url: "涵山舍.png"},
        {url: "和乐轩.png"},
        {url: "养生塘.png"},
        {url: "京华茶苑.png"},
        {url: "儒士馆.png"},
        {url: "mmexport1644135881973.jpg"},
        {url: "mmexport1644331307165.jpg"},
        {url: "mmexport1644331349969.jpg"},
        {url: "QQ截图20220224101702.jpg"},
      ],
    };
  },
  methods: {
    screenType() {
      this.screenWidth = document.body.offsetWidth;
      this.pageCount = (this.screenWidth + 30) / 354 * 3;
      if (this.pageCount < 6) {
        this.pageCount = 3;
      } else if (this.pageCount < 9) {
        this.pageCount = 6
      } else if (this.pageCount < 12) {
        this.pageCount = 9
      } else if (this.pageCount < 15) {
        this.pageCount = 12
      } else if (this.pageCount < 18) {
        this.pageCount = 15
      } else if (this.pageCount < 21) {
        this.pageCount = 18
      } else if (this.pageCount < 24) {
        this.pageCount = 21
      } else if (this.pageCount < 27) {
        this.pageCount = 24
      } else {
        this.pageCount = 27
      }
      this.pageNum =Math.ceil(this.picNum/this.pageCount)-1;
      //
      for (let i = 0; i < this.dataList.length/this.pageCount+1; i++) {
        if (i === 0){
          this.list1=this.dataList.slice(0,this.pageCount);
        }else if (i === 1){
          this.list2 =this.dataList.slice(this.pageCount,this.pageCount*2);
        }else if (i===2){
          this.list3 =this.dataList.slice(this.pageCount*2,this.pageCount*3);
        }else if (i===3){
          this.list4 = this.dataList.slice(this.pageCount*3,this.pageCount*4);
        }else if (i===4){
          this.list5 = this.dataList.slice(this.pageCount*4,this.pageCount*5);
        }else if (i===5){
          this.list6 = this.dataList.slice(this.pageCount*5,this.pageCount*6);
        }else if (i===6){
          this.list7 = this.dataList.slice(this.pageCount*6,this.pageCount*7);
        }else if (i===7){
          this.list8 = this.dataList.slice(this.pageCount*7,this.pageCount*8);
        }else if (i===8){
          this.list9 = this.dataList.slice(this.pageCount*8,this.pageCount*9);
        }else if (i===9){
          this.list10 = this.dataList.slice(this.pageCount*9,this.pageCount*10);
        }
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.screenType);
    this.screenWidth = document.body.offsetWidth;
    this.screenType();
  },
  unmounted() {
    window.removeEventListener('resize', this.screenType)
  },
  beforeDestroy() {
  }
};
</script>

<style scoped>
/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {
  .el_carousel_popupTop {
    display: none;
  }

  .phonePopup {
    display: block;
    text-align: center;
  }

  .el_carousel_popup {
    display: inline-block;
    width: 100%;
    min-width: 100%;
  }
  .popupCard{
    min-width: 350px;
  }
  /*.el_carousel_popupTop {*/
  /*  display: block;*/
  /*}*/

  /*.el_carousel_popup {*/
  /*  display: none;*/
  /*}*/

}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 768px) and (max-width: 992px) {
  /*.el_carousel_popupTop {*/
  /*  display: none;*/
  /*}*/
  /*.el_carousel_popup {*/
  /*  display: block;*/
  /*}*/
  .el_carousel_popupTop {
    display: block;
  }

  .el_carousel_popup {
    display: none;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 992px) {
  .el_carousel_popupTop {
    display: block;
  }

  .el_carousel_popup {
    display: none;
  }

}
</style>
