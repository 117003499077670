<template>

  <div>
    <div class="container">

      <div class="row">

        <div class="col-md-8 col-md-offset-2">
          <div class="title-content">
            <h2 class="section-title">管理团队</h2>
          </div>
        </div>
      </div> <!-- /.row -->

    </div> <!-- /.container -->

    <div class="about-team slider-container" style="text-align: center">

      <div class="divTop">
        <!--    框架-->
        <div class="divH" v-for="(item,index) in mingRenList" :key="index">
          <!--          :class="{'IsMobileByImg':!bIsMobile}"/-->
          <!--      上层-->
          <div class="divH_div">
            <!--        图片 -->
            <div>
              <img class="divH_div_Img" :src="item.url">
              <!--        标题-->
              <div class="divH_div_title">
                <!--          主-->
                <div class="divH_div_title_div">
                  <div class="divH_div_title_div_div">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--      下层-->
          <div class="divH_LDiv">
            <p class="divH_LDiv_p">
              {{ item.content }}
            </p>
          </div>
        </div>
      </div>

      <!--手机端显示-->

      <div class="phoneDivTop">
        <!--   内容显示区域     -->
        <div class="phone_content" id="phone_show">
          <div v-for="(item,index) in mingRenList" :key="index" v-show="perIndex === index">
            <div class="content_img">
              <img :src="item.url" alt="" style="border-radius: 50%">
            </div>
            <div class="content_rightDiv">
              <div class="content_name">
                {{ item.title }}
              </div>
              <div class="content_title">
                {{ item.secondTitle }}
              </div>
            </div>
            <div class="content_detail">
              {{ item.content }}
            </div>
          </div>
        </div>
        <!--   人物切换区域   -->
        <div class="phone_tabs">
          <!--     循环区域     -->
          <a class="tabs_content" href="#phone_show" v-smooth-scroll="{offset:-200}" v-for="(item,index) in mingRenList"
             :key="index" @click="changePer_Func(index)">
            <div class="tabs_content_name">
              {{ item.title }}
            </div>
            <div class="tabs_content_workName">
              {{ item.secondTitle }}
            </div>
            <div class="tabs_content_icon">

            </div>
          </a>
        </div>
      </div>
    </div> <!-- /.about-team -->


  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 0
    },
    bIsMobile: false,
  },
  name: "HomeMainAbout",
  data() {
    return {
      perIndex: 0,
      // 创始人兼CEO 北京金米天成科技发展有限公司 总经理 中国星级茶馆评定行业专家 中国茶馆办 首席数字化专家
      mingRenList: [
        {
          url: require("../../assets/mingRen/mingRen1.png"),
          title: "耿文军",
          secondTitle: " 创始人& 董事长",
          content: '金米天成创始人&董事长、中国茶馆办 首席数字化专家、中国星级茶馆行业专家、曾任北京应用技术大学 学科带头人高级教师、曾担任中国教育电视台CETV《培训中国》主讲人',
        }, {
          url: require("../../assets/mingRen/mingRen3.png"),
          title: "李卓澄",
          secondTitle: "高级副总裁、首席品牌官",
          content:
              '清华大学博士、金米天成高级副总裁、首席品牌官、小茶馆品牌创始人、连锁茶馆经营专家',
        }, {
          url: require("../../assets/mingRen/mingRen2.png"),
          title: "蔡晓东",
          secondTitle: "高级副总裁、首席战略官",
          content:
              '毕业于中国农业大学、金米天成高级副总裁、首席战略官、博鳌国际茶馆业发展论坛执行秘书长',
        }, {
          url: require("../../assets/mingRen/mingRen4.png"),
          title: "赵书君",
          secondTitle: "高级副总裁、首席知识官",
          content:
              '毕业于四川农业大学茶学系、金米天成高级副总裁、首席知识官、茶艺人培训事业部总经理、国家高级评茶员、国家高级茶艺师',
        }
      ]
    }
  },
  methods: {
    changePer_Func(index) {
      this.perIndex = index
    }
  }
}
</script>

<style scoped>
.divTop {
  display: inline-block;
  width: 1200px
}

/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {

  .about-team {
    margin-top: 0;
  }

  .divTop {
    display: none;
  }

  .phoneDivTop {
    display: block;
    color: black;
  }

  .phone_content {
    width: 80%;
    margin: 0 auto;
    text-align: left;
  }

  .content_img {
    display: inline-block;
    vertical-align: middle;
  }

  .content_rightDiv {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
  }

  .content_name {
    width: 100%;
    font-size: 18px;
    color: #e4b118;
    text-align: left;
  }

  .content_title {
    margin-top: 5px;
    font-size: 14px;
  }

  .content_detail {
    margin-top: 5px;
    font-size: 13px;
  }

  .phone_tabs {
    width: 90%;
    margin: 0 auto;
  }

  .tabs_content {
    width: 100%;
    height: 90px;
    background: #fff;
    text-align: left;
    position: relative;
    margin-top: 20px;
    display: block;
    color: black;
    box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);
    border-radius: 8px;
  }

  a {
    text-decoration: none;
  }

  .tabs_content_name {
    width: 80%;
    height: 50%;
    font-size: 18px;
    color: #e4b118;
    padding: 20px;
  }

  .tabs_content_workName {
    width: 80%;
    height: 50%;
    font-size: 14px;
    padding-left: 20px;
  }

  .tabs_content_icon {
    right: 10px;
    bottom: 10px;
    position: absolute;
    width: 20px;
    height: 20px;
    background: url("../../assets/mingRen/跳转.png") no-repeat center center;
    background-size: cover;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 768px) and (max-width: 992px) {

  .divTop {
    display: inline-block;
    width: 100%
  }
  .phoneDivTop {
    display: none;
  }

  .divH {
    width: 70%;
    /* max-width: 450px; */
    height: auto;
    /*border-radius: 12px;*/
    background: #ffffff;
    display: inline-block;
    margin: 3px;
    /*margin-bottom: 20px;*/
    min-height: 240px;
    box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);
    /*border-radius: 8px;*/
  }

  .divH_LDiv {
    padding: 0 20px
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 992px) {

  .phoneDivTop {
    display: none;
  }

  .divH {
    width: 500px;
    /* max-width: 450px; */
    height: auto;
    /*border-radius: 12px;*/
    background: #ffffff;
    display: inline-block;
    margin: 3px;
    /*margin-bottom: 20px;*/
    min-height: 240px;
    box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);
    /*border-radius: 8px;*/
  }

  .divH_LDiv {
    padding: 0 20px
  }
}

.divH_div {
  width: 90%;
  padding: 20px 20px 0 20px;
}

.divH_div_Img {
  height: 95px;
  display: inline-block;
  border-radius: 50%;
  width: 95px;
  transition: all 0.5s;
}

.divH_div_title {
  display: inline-block;
  justify-content: center;
  align-items: center
}

.divH_div_title_div {
  display: inline-block
}

.divH_div_title_div_div {
  color: black;
  font-size: 25px;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-top: 20px
}

.divH_div_title_div_divL {
  margin-left: 20px;
  display: inline-block
}


.divH_LDiv_p {
  white-space: pre-line;
  margin: 10px;
  font-size: 15px;
  /*padding-bottom: 5px;*/
  display: -webkit-inline-flex;
  /*width: 300px;*/
  text-indent: 2em;
  text-align: left;
  padding: 10px 50px;
}

.IsMobileByImg {
  /*width: 35%;*/
  margin: 23px;
}

.divH_div_Img:hover {
  transform: scale(1.2);
  transition: all 0.5s;
}

</style>
