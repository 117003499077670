<template>
  <div>
    <svg width="20" height="20" viewBox="0 0 21 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15.255 3.998L8.587 14.37 5.35 10.162l-.078-.09-.01-.01a1.084 1.084 0 00-1.62 1.432l4.174 5.427.078.09.01.009c.48.477 1.295.405 1.674-.184l7.508-11.679.057-.104.006-.012a1.083 1.083 0 00-1.894-1.043z"
            fill="#1DC63A"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "check"
}
</script>

<style scoped>

</style>