<template>
  <div class="row">
    <div class="col-md-8 col-md-offset-2">
      <div class="title-content">
        <h2 class="section-title">专注茶行业</h2>
      </div>
    </div>
  </div>
  <NewForService></NewForService>
  <div class="row row_custom">
    <div class="col-md-8 col-md-offset-2">
      <div class="title-content">
        <h2 class="section-title">产品与服务</h2>
      </div>
    </div>
  </div>
</template>
<script>
import HomeMainContact from "@/components/HomeMain/Contact.vue";
import NewForService from "@/components/PageTools/NewForService";


export default {
  components: {
    HomeMainContact,
    NewForService
  },
  props: {
    height: {
      type: Number,
      default: 0
    }
  },
  name: "HomeMainForServices",
  data() {
    return {
      squareIndex: 1,
      serviceList: [
        {
          img: "服务7.jpg",
          name: "茶楼茶馆茶会所茶书院"
        },
        {
          img: "服务1.jpg",
          name: "茶店茶叶品牌专柜"
        },
        {
          img: "服务2.jpg",
          name: "茶企连锁",
          secondName: '(直营店、代理商、加盟店)',
        },
        {
          img: "服务6.jpg",
          name: "新式茶饮",
          secondName: "(现制茶、调饮茶)"
        },
        {
          img: "服务5.jpg",
          name: "茶仓"
        },
        {
          img: "服务3.jpg",
          name: "茶会、茶旅"
        },
        {
          img: "服务4.jpg",
          name: "茶艺培训机构、茶学院"
        },
        {
          img: "无人茶室.jpeg",
          name: "智慧茶馆、无人茶室"
        }
      ]
    }
  },
  methods: {
    squareMouse(index) {
      this.squareIndex = index;
    },
    leaveSquareMouse() {
      this.squareIndex = 1;
    },
    change_md_Func(num) {
      this.index = num;
    },
    toSystem(url) {
      this.$router.push({path: url})
    },
    toJiCai() {
      this.$router.push({path: "/chaguanjicaiIntro"})
    }
  }
}
</script>

<style scoped>


/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {

  .row_custom {
    padding-top: 20px
  }

  .phoneService {
    display: block;
  }

  .phoneService_div_ul {
    width: 100%;
    /*height: 300px;*/
  }

  .phoneService_div_ul_li {
    width: 50%;
    height: 100%;
    display: inline-block;
    text-align: center;
    padding-top: 10px;
  }

  .phoneService_div_ul_li_div {
    width: 100%;
    height: 150px;
    display: inline-block;
  }

  .phoneService_div_ul_li_img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .phoneService_div_ul_li_title {

  }

  .phoneService_div_ul_li_title {

  }

  .service_div {
    display: none;
  }

  .content {
    display: none;
  }

  .phoneContent {
    display: block;
    color: #000000;
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .tabsGroup {
    width: 100%;
    height: 40px;
    /*background: #cccccc;*/
  }

  .tabsGroup ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }

  .tabsGroup ul li {
    display: inline-block;
    width: 25%;
    height: 100%;
    text-align: center;
    padding: 10px;
  }

  .tabsGroup_ul_li_active {
    color: #57b05b;
    border-top: 2px solid #57b05b;
  }

  .pageGroup .pageGroup_div {
    /*max-width: 450px;*/
    height: auto;
    /* border-radius: 12px; */
    /*background: #ffffff;*/
    display: inline-block;
    margin: 3px;
    /* margin-bottom: 20px; */
    min-height: 300px;
    /* width: 61%; */
    box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);
    border-radius: 8px;

    white-space: pre-line;
    font-size: 15px;
    /* padding-bottom: 5px; */
    display: -webkit-inline-flex;
    /* width: 300px; */
    text-indent: 2em;
    text-align: left;
    padding: 10px 13px;
  }

  @keyframes animated-border {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
  }

  .pageGroup .pageGroup_div {
    animation: animated-border 1.5s infinite;
    /*font-family: Arial, serif;*/
    font-size: 14px;
    line-height: 30px;
    /*font-weight: bold;*/
    color: #000000;
    border: 2px solid;
    border-radius: 10px;
    padding: 15px;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 768px) {

  .row_custom {
    padding-top: 50px
  }

  .phoneService {
    display: none;
  }

  .phoneContent {
    display: none;
  }

  /*animi*/
  .slide-top {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  /* ----------------------------------------------
   * Generated by Animista on 2022-11-21 11:53:0
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info.
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  /**
   * ----------------------------------------
   * animation slide-top
   * ----------------------------------------
   */
  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-25px);
      transform: translateY(-25px);
    }
  }

  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-25px);
      transform: translateY(-25px);
    }
  }

  .slide-bottom {
    -webkit-animation: slide-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  /* ----------------------------------------------
   * Generated by Animista on 2022-11-21 11:55:14
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info.
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  /**
   * ----------------------------------------
   * animation slide-bottom
   * ----------------------------------------
   */
  @-webkit-keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
  }

  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(25px);
      transform: translateY(25px);
    }
  }

  /*服务*/
  .service_div {
    width: 100%;
    height: 200px;
    /*background: #1b6d85*/
  }

  .service_div_ul {
    width: calc(200px * 8 + 6px * 7);
    height: 100%;
    display: flex;
    margin: 0 auto;
  }

  .service_div_ul_li {
    height: 100%;
    margin: 3px;
  }

  .service_div_ul_li_div {
    width: 200px;
    height: 100%;
    text-align: center;
  }

  .service_div_ul_li_img {
    width: 200px;
    height: 130px;
    display: inline-block;
    background-size: cover;
    box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);
  }

  .service_div_ul_li_title {
    font-size: 16px;
  }

  /*产品*/
  .content {
    height: 550px;
    /*background: rgb(244, 242, 238);*/
    /*background: #000000;*/
    /*padding-bottom: 100px;*/
    display: flex;
    justify-content: center;
  }

  .OuterLayer {
    display: flex;
    width: 80%;
    height: 95%;
    /*margin-top: 100px;*/
  }

  .OuterLayerSquareGroup {
    display: inline-flex;
    flex-direction: column;
    width: 49.8%;
    height: 100%;
    /*overflow: hidden;*/
    /*border-right: 1px solid #707070;*/
    box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);
    /*border-radius: 8px;*/
    margin: 3px;
  }

  .square {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    height: 49.9%;
    overflow: hidden;
    transition: height .5s;
  }


  .squareLeftTop_hover {
    height: 120vh;
  }

  .content-img {
    position: absolute;
    top: 48px;
    left: 13%;
    width: 90px;
    height: 96px;
    transition: top .3s, left .3s, width .3s;
  }

  .img-inner {
    width: 100%;
  }

  .content-words {
    position: absolute;
    top: 30px;
    left: 10%;
    width: 85%;
    height: 100%;
    transition: top .3s, left .3s, width .3s;
  }

  .content-img_hover {
    left: 32px;
    top: 32px;
  }

  .content-words_hover {
    top: 20px;
    left: 20px;
    width: 90%;
  }

  .content-name {
    font-size: 30px;
    font-weight: 500;
    color: #000000;
  }

  .link_hover {
    height: 0;
  }

  .content-desc {
    display: -webkit-box;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 100;
    line-height: 32px;
    color: #000000;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    line-clamp: 20;
  }

  contentdOK {
    position: relative;
    z-index: 3;
  }

  .link-dZG06 {
    position: relative;
    z-index: 3;
    /*opacity: 1;*/
  }

  .goto {
    display: flex;
    width: 100%;
    height: 110px;
    line-height: 110px;
    color: #000000;
    pointer-events: none;
    border-top: 1px solid #707070;
    opacity: 1;
    transition: opacity .5s;
  }

  .goto-visible {
    pointer-events: auto;
  }

  .goto-word-K9ve3 {
    width: 70%;
    height: 100%;
    font-size: 22px;
    /*color: #e4b118;*/
    /*border-right: 1px solid #707070;*/
  }

  .goto svg {
    width: 48px;
    margin: auto;
    /*fill: #fff;*/
  }

  .bg-hXcbf_hover {
    opacity: 1;
  }

  .bg-hXcbf {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }
}


a {
  color: #1890ff;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  cursor: pointer;
  transition: color .3s;
}


.squareLeft {
  /*border-left: 1px solid #707070;*/
}


</style>
