<template>
  <div class="home">
    <!--  头部  -->
    <home-header :is-show-back="false" :is-child-page="true"></home-header>
    <!--  内容区域  -->
    <!--  数字化系统详情  -->
    <main style="margin-top: 100px">
      <div class="group">
        <div
            :class="isShowLeftImg === 1 ?'group_left_Two':isShowLeftImg === 2 ?'group_right_Three':isShowLeftImg === 3?'group_right_Three':'group_left_One'">
          <!--          <img src="" alt="" style="width: 100%;height: 100%">-->
          <div class="group_absorbs">
            <el-tooltip
                class="box-item"
                effect="dark"
                content="多元化管理模式"
                placement="right"
            >
              <a class="group_absorbs_dots dos1"
                 :style="numIndex === 1?islagnum?'background:red':'background:#A9A9A9':''"
                 v-smooth-scroll="{ duration: 1000, offset: -150 }" href="#tittle_1">
              </a>
            </el-tooltip>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="行业领军者"
                placement="right"
            >
              <div class="group_absorbs_dots dos2"
                   :style="numIndex === 2?islagnum?'background:red':'background:#A9A9A9':''"
                   v-smooth-scroll="{ duration: 1000, offset: -150 }" href="#tittle_2">

              </div>
            </el-tooltip>


            <el-tooltip
                class="box-item"
                effect="dark"
                content="线上商城"
                placement="right"
            >
              <div class="group_absorbs_dots dos3"
                   :style="numIndex === 3?islagnum?'background:red':'background:#A9A9A9':''"
                   v-smooth-scroll="{ duration: 1000, offset: -150 }" href="#tittle_3">

              </div>
            </el-tooltip>


            <el-tooltip
                class="box-item"
                effect="dark"
                content="感动式服务"
                placement="right"
            >
              <div class="group_absorbs_dots dos4"
                   :style="numIndex === 4?islagnum?'background:red':'background:#A9A9A9':''"
                   v-smooth-scroll="{ duration: 1000, offset: -150 }" href="#tittle_4">

              </div>
            </el-tooltip>


            <el-tooltip
                class="box-item"
                effect="dark"
                content="历久弥新"
                placement="right"
            >
              <div class="group_absorbs_dots dos5"
                   :style="numIndex === 5?islagnum?'background:red':'background:#A9A9A9':''"
                   v-smooth-scroll="{ duration: 1000, offset: -150 }" href="#tittle_5">
              </div>
            </el-tooltip>


          </div>
        </div>
        <div class="group_right">
          <div class="group_right_title" id="tittle_1">
            全面支持包间、快销和现制茶饮模式
          </div>
          <div class="group_right_div">
            <div class="group_right_group">
              <div class="group_right_group_div">
                <img class="group_right_group_div_img" src="../../assets/ChildPage/numberSystem/number1.png"
                     alt="">
              </div>
              <div class="group_right_group_div_div">
                <div class="group_right_group_div_div_title">
                  包间模式
                </div>
                <div class="group_right_group_div_div_span">
                  适用于传统茶馆
                </div>
                <div class="group_right_group_div_div_span">
                  精细化包间计费管理，消费内容清晰明了
                </div>
                <div class="group_right_group_div_div_span">
                  不再纠结计时问题
                </div>
              </div>
            </div>

            <div class="group_right_group">
              <div class="group_right_group_div">
                <img class="group_right_group_div_img" src="../../assets/ChildPage/numberSystem/number2.png"
                     alt="">
              </div>
              <div class="group_right_group_div_div">
                <div class="group_right_group_div_div_title">
                  快销模式
                </div>
                <div class="group_right_group_div_div_span">
                  适用于茶店、品牌专柜
                </div>
                <div class="group_right_group_div_div_span">
                  快速便捷，销售自动出库
                </div>
                <div class="group_right_group_div_div_span">
                  库存精准，实时库存一目了然
                </div>
              </div>
            </div>

            <div class="group_right_group">
              <div class="group_right_group_div">
                <img class="group_right_group_div_img" src="../../assets/ChildPage/numberSystem/number3.png"
                     alt="">
              </div>
              <div class="group_right_group_div_div">
                <div class="group_right_group_div_div_title">
                  现制茶饮
                </div>
                <div class="group_right_group_div_div_span">
                  适用于新式茶饮,现制奶茶
                </div>
                <div class="group_right_group_div_div_span">
                  支持配料库存管理、操作简单便捷，助力快速出单
                </div>
              </div>
            </div>
          </div>

          <div class="group_right_title" style="margin-top: 100px" id="tittle_2">
            <!--            十大优势,成就行业领军者-->
            系统功能概览
          </div>

          <div style="text-align: left;display: inline-block;margin-bottom: 100px;padding-left: 100px">
            <!--            一-->
            <div class="group_right_secondTitle magTop80">
              <!--              一、管理系统,远不止收银-->
              一、前台营业
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                <!--                集成六大系统，全流程数据同步-->
                主要包括商品销售、包间开单、预定、结账、消费转台、合并账单等操作。
              </div>
              <div>
                <!--                收银系统、库管系统、会员管理系统、员工管理系统、财务管理系统、线上商城-->
              </div>
            </div>
            <img class="" src="../../img/intro/前台营业.png" style="width: 80%"
                 alt="">
            <!--            二-->
            <div class="group_right_secondTitle magTop80">
              <!--              二、强大数据引擎，轻松完成立体架构管理-->
              二、基础档案
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                <!--                单店可用，更适用于连锁多店-->
                用于设置新店的一些基础信息，例如：员工、仓库、包间桌台计费方式、商品信息等。
              </div>
              <div>
                <!--                具有总部结构，支出会员共享-->
              </div>
              <div>
                <!--                总部一端在手，即可掌握所有分店数据-->
                <div style="font-size: 14px">
                  <!--                  *总部统计所有分店数据，分店可管理本店数据-->
                </div>
              </div>
              <img class="" src="../../img/intro/基础档案.png" style="width: 80%"
                   alt="">
            </div>
            <!--            三-->
            <div class="group_right_secondTitle magTop80">
              <!--              三、自动计时计费，灵活又精准-->
              三、库存管理
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                <!--                消费数据清晰呈现，顾客从此不再抱怨-->
              </div>
              <div>
                <!--                全程自动管理，减轻店内员工压力-->
              </div>
              <div>
                <!--                高峰时段，可灵活设置计费-->
              </div>
              <div>
                <!--                最大支持前两轮包段后再计时计费，自定计费刻度，计时片段-->
                系统集成的智能仓库管理模块，销售自动减库。动态盘点设计，让盘点可随时进行，不再是库管难点。
              </div>
              <img class="" src="../../img/intro/库存管理.png" style="width: 80%"
                   alt="">
            </div>
            <!--            四-->
            <div class="group_right_secondTitle magTop80">
              <!--              四、不想用电脑？没关系，各项业务，手机也能轻松完成-->
              四、会员管理
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                <!--                微信服务号一体化-->
              </div>
              <div>
                <!--                管理端：数据实时同步，出差在外也能随时掌握店内动态-->
              </div>
              <div>
                <!--                员工端：门店业务轻松完成，查看个人业绩更不在话下-->
                微信粉丝转化会员，微信粉丝、散客首单折扣；顾客扫码开单/结账，会员与非会员优惠明显，吸引散客转化会员。
              </div>
              <div>
                <!--                会员端：消费数据清晰明了，余额对账精准无误，可随时查看个人茶仓-->
                <div style="font-size: 14px">
                  <!--                  *个人茶仓指会员在门店的存茶-->
                </div>
              </div>
              <img class="" src="../../img/intro/会员管理.png" style="width: 80%"
                   alt="">
            </div>
            <!--            五-->
            <div class="group_right_secondTitle magTop80">
              <!--              五、捕获自然流量，高效转会员-->
              五、短信平台
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                <!--                自然流量高效转会员-->
                对接三大通信运营商，提供平台短信充值渠道，用于客户充值、消费、预定等功能，随业务发送短信通知（支持群发短信）。
              </div>
              <div>
                <!--                散客通过微信付款，首单可享95折(可设置)，即成为本店普通会员-->
              </div>
              <div>
                <!--                顾客自助结账，散客价格与会员价格优惠对比明显，吸引顾客储值-->
              </div>
              <img class="" src="../../img/intro/短信平台.png" style="width: 80%"
                   alt="">
            </div>
            <!--            六-->
            <div class="group_right_secondTitle magTop80">
              <!--              六、会员在店珍藏的茶与器，也可方便管理-->
              六、财务报表
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                <!--                存取茶皆有短信推送，微信同步推送存量变化-->
                对海量数据进行分类、抽取、挖掘、得到往期和现在经营的全局视图，商业价值多维画像，图表呈现给管理者，提供决策支持。
              </div>
              <div>
                <!--                使会员悉知实时存量-->
              </div>
              <div>
                <!--                会员主人杯存店，照片入档、便捷管理-->
              </div>
              <div>
                <!--                支持会员异地存取茶-->
              </div>
              <img class="" src="../../img/intro/财务报表.png" style="width: 80%"
                   alt="">
            </div>
            <!--            七-->
            <div class="group_right_secondTitle magTop80">
              <!--              七、销售自动出库，智能动态盘点-->
              七、采购管理
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                <!--                集成的智能仓库管理系统-->
                对供应商档案的管理、采购预付款和入库单付款的管理（支持多批次付款）以及采购报表的统计。
              </div>
              <div>
                <!--                不同销售单位可对应不同库存单位，销售自动减库。-->
              </div>
              <div>
                <!--                动态盘点设计,区间变动生成盘点任务，精准盘点减轻盘点压力-->
              </div>
              <div>
                <!--                让盘点可随时进行，不再是库管难点。-->
              </div>
              <img class="" src="../../img/intro/采购管理.png" style="width: 80%"
                   alt="">
            </div>
            <!--            八-->
            <div class="group_right_secondTitle magTop80">
              <!--              八、角色清晰，权限分明-->
              八、批发管理
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                <!--                支持个性化权限定制-->
                对代理商档案的管理、代理商预付款和批发单付款的管理（支持多批次收款）以及批发报表的统计。
              </div>
              <div>
                <!--                无论是老板、股东，还是店长、经理-->
              </div>
              <div>
                <!--                抑或是主管、领班、 财务、收银、库管人员、茶艺师-->
              </div>
              <div>
                <!--                都可以各司其职，权限分明-->
              </div>
              <img class="" src="../../img/intro/批发管理.png" style="width: 80%"
                   alt="">
            </div>
            <!--            九-->
            <div class="group_right_secondTitle magTop80">
              <!--              九、商业智能BI-助力科学决策-->
              九、商城管理
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                <!--                将店内数据进行有效的整合，快速准确的提供报表和决策依据，帮助您做出更明智的经营决策-->
                线上线下一体化，会员在商城上可用会员卡消费，享受店内同样折扣。
              </div>
              <div>
                <!--                多维度分析：可按照商品、品类、员工、包间、外卖、商城等多角度-->
              </div>
              <div style="text-indent: 8em">
                <!--                对近三到五年的数据进行按月或按年的营收对比和利润贡献分析-->
              </div>
              <div>
                <!--                对海量数据进行分类、抽取、挖掘、得到往期和现在经营的全局视图-->
              </div>
              <div>
                <!--                形成商业价值多维画像，将图表呈现给您，提供准确数据支撑的决策支持。-->
              </div>
              <img class="" src="../../img/intro/商城管理.png" style="width: 80%"
                   alt="">
            </div>
          </div>


          <div class="group_right_title" style="margin-top: 100px" id="tittle_3">
            线上商城，助力开辟私域流量
          </div>

          <div style="text-align: left;display: inline-block;margin-bottom: 100px;padding-left: 100px">
            <!--            一-->
            <div class="group_right_secondTitle magTop80">
              一、线上商城一体化运营模式
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                线上商城，与线下店内一体化，会员在线上商城上可用储值卡余额消费
              </div>
              <div>
                享受店内同样的折扣
              </div>
            </div>
            <!--            二-->
            <div class="group_right_secondTitle magTop80">
              二、稳定兼容多种支付模式
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                支付功能。支持微信支付、财付通、快钱、银联、货到付款等多种支付方式，
              </div>
              <div>
                解决了商家因单一支付方式给消费者带来的不便。
              </div>
              <div>
                购物车/订单/结算功能。完善的购物车和订单生成系统，在线结算方便快捷。
              </div>
            </div>
            <!--            三-->
            <div class="group_right_secondTitle magTop80">
              三、菜单支持自定义
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                拥有商品分类、资讯中心、新品促销等版块，分类清晰
              </div>
              <div>
                除了微信自定义菜单还能扩展到内页中自定义菜单。
              </div>

            </div>
            <!--            四-->
            <div class="group_right_secondTitle magTop80">
              四、强大的产品管理系统
            </div>
            <div class="group_right_group_div_div_secondTitle">
              <div>
                支持可以自定义参数、批量导入、批量导出等功能。
              </div>
              <div>
                化繁为简，轻松完成商城管理。
              </div>
            </div>
          </div>

          <!--          <div class="group_right_title" style="margin-top: 100px" id="tittle_4">-->
          <!--            ===-->
          <!--          </div>-->

          <!--          <div style="text-align: left;display: inline-block;margin-bottom: 100px;padding-left: 100px">-->
          <!--            &lt;!&ndash;            一&ndash;&gt;-->
          <!--            <div class="group_right_secondTitle magTop80">-->
          <!--              1分钟联系客服-->
          <!--            </div>-->
          <!--            <div class="group_right_secondTitle magTop80">-->
          <!--              3分钟定位问题-->
          <!--            </div>-->
          <!--            <div class="group_right_secondTitle magTop80">-->
          <!--              5分钟解决问题-->
          <!--            </div>-->
          <!--            <div class="group_right_secondTitle magTop80">-->
          <!--              1天发布新版本-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="group_right_title" id="tittle_5" style="margin-top: 100px">-->
          <!--            ===-->
          <!--          </div>-->
          <!--          <div style="text-align: left;display: inline-block;margin-bottom: 100px;padding-left: 100px">-->
          <!--            &lt;!&ndash;            一&ndash;&gt;-->
          <!--            <div class="group_right_secondTitle magTop80">-->
          <!--              强大的技术团队，3000+次系统迭代-->
          <!--            </div>-->
          <!--            <div class="group_right_secondTitle magTop80">-->
          <!--              平均每周一次更新-->
          <!--            </div>-->
          <!--            <div class="group_right_secondTitle magTop80">-->
          <!--              专属售后全天候待命，系统更新免费升级-->
          <!--            </div>-->
          <!--            <div class="group_right_secondTitle magTop80">-->
          <!--              远程协助手把手教学，定期培训答疑解惑-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <HomeMainPriceIntroduction></HomeMainPriceIntroduction>-->
        </div>
      </div>
    </main>
    <!--  底部  -->
    <!--    <home-footer></home-footer>-->
  </div>
</template>

<script>
import homeHeader from "@/components/HomeHeader";
import homeFooter from "@/components/HomeFooter";
import HomeMainPriceIntroduction from "@/components/HomeMain/PriceIntroduction.vue";
import {ElNotification} from 'element-plus'

export default {
  name: "systemIntro",
  components: {
    homeHeader,
    homeFooter,
    HomeMainPriceIntroduction
  },
  data() {
    return {
      isShowLeftImg: 1,
      numIndex: 1,
      islagnum: false,
      numList: [],
      distanceList: [],
      halfClientHeight: '',
    }
  },
  methods: {
    isScroll() {
      const top = document.documentElement.scrollTop;
      if (this.distanceList[0] < this.halfClientHeight + top && this.halfClientHeight + top < this.distanceList[1]) {
        this.isShowLeftImg = 1;
        this.numIndex = 1;
      } else if (this.distanceList[1] < this.halfClientHeight + top && this.halfClientHeight + top < this.distanceList[2]) {
        this.isShowLeftImg = 2;
        this.numIndex = 2;
      } else if (this.distanceList[2] < this.halfClientHeight + top && this.halfClientHeight + top < this.distanceList[3]) {
        this.isShowLeftImg = 3;
        this.numIndex = 3;
      } else if (this.distanceList[3] < this.halfClientHeight + top && this.halfClientHeight + top < this.distanceList[4]) {
        this.isShowLeftImg = 4
        this.numIndex = 4;
      } else if (this.halfClientHeight + top > this.distanceList[4]) {
        this.isShowLeftImg = 5
        this.numIndex = 5;
      }
    },
    lagnum(v) {
      if (this.numList.length === 0) {
        if (v === 1) {
          ElNotification({
            title: '恭喜您发现了彩蛋的第一步',
            message: "你已发现神秘密码的第一个数字,还剩下三个哦!提示[感动式服务]",
          })
        }
      }
      this.numList.push(v);
      if (this.numList.length >= 4) {
        if (
            this.numList[0] === 1 &&
            this.numList[1] === 3 &&
            this.numList[2] === 5 &&
            this.numList[3] === 1) {
          ElNotification({
            title: '彩蛋开启',
            message: "红色信号灯已启用",
          })
          this.islagnum = true;
        } else {
          this.islagnum = false;
        }
      }
    },
    menuToTopDis(id) {
      var div = document.querySelector(id)

      function calc(dom, distance) {
        distance = distance || 0
        //可以使用dom.style.positin 但style只能获取到行内属性会出现获取不到属性值返回空字符串
        //getComputedStyle会输出最终应用于该element上的最终样式  IE9+支持
        //currentStyle  IE老版本支持
        if (['relative', 'absolute', 'fixed'].includes(getComputedStyle(dom).position)) {
          // distance += distance.offsetTop
        }
        if (dom.parentElement.nodeName === 'BODY' || !dom.parentElement) {
          return distance
        }
        return calc(dom.parentElement, distance)
      }

      //最终结果
      let distance = 0;
      return distance;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.isScroll);
    for (let i = 1; i < 6; i++) {
      this.distanceList.push(this.menuToTopDis("#tittle_" + i) - 100);
    }
    // s = " 网页可见区域宽：" +document.body.clientWidth;
    this.halfClientHeight = window.screen.availHeight / 2 - 100;
    // s = " 网页可见区域宽：" +document.body.offsetWidth+ " (包括边线和滚动条的宽)";
    // s = " 网页可见区域高：" +document.body.offsetHeight+ " (包括边线的宽)";
    // s = " 网页正文全文宽："+ document.body.scrollWidth;
    // s = " 网页正文全文高：" +document.body.scrollHeight;
    // s = " 网页被卷去的高(ff)："+ document.body.scrollTop;
    // s = " 网页被卷去的高(ie)："+ document.documentElement.scrollTop;
    // s = " 网页被卷去的左："+ document.body.scrollLeft;
    // s = " 网页正文部分上：" +window.screenTop;
    // s = " 网页正文部分左："+ window.screenLeft;
    // s = " 屏幕分辨率的高：" +window.screen.height;
    // s = " 屏幕分辨率的宽："+ window.screen.width;
    // s = " 屏幕可用工作区高度：" +window.screen.availHeight;
    // s = " 屏幕可用工作区宽度："+ window.screen.availWidth;
    // s = " 你的屏幕设置是 "+ window.screen.colorDepth +" 位彩色";
    // s = " 你的屏幕设置 "+ window.screen.deviceXDPI+ " 像素/英寸";
  },
  unmounted() {
    window.removeEventListener('scroll', this.isScroll)
  },
}
</script>

<style scoped>
.dotsBack {
  background: #A9A9A9;
}

.group {
  width: 100%;
  height: calc(100vh - 100px);
  /*margin-top: 100px;*/
  background: #ffffff;
}

/*noinspection CssUnknownTarget*/
.group_left_One {
  vertical-align: middle;
  display: inline-block;
  position: fixed;
  width: 30%;
  height: calc(100% - 100px);
  background: url("../../assets/ChildPage/numberSystem/system3.png") no-repeat center;
  background-size: cover;
}

/*noinspection CssUnknownTarget*/
.group_left_Two {
  vertical-align: middle;
  display: inline-block;
  position: fixed;
  width: 30%;
  height: calc(100% - 100px);
  background: url("../../assets/ChildPage/numberSystem/system2.png") no-repeat center;
  background-size: cover;
}

/*noinspection CssUnknownTarget*/
.group_right_Three {
  vertical-align: middle;
  display: inline-block;
  position: fixed;
  width: 30%;
  height: calc(100% - 100px);
  background: url("../../assets/ChildPage/numberSystem/system1.png") no-repeat center;
  background-size: cover;
}


.group_right {
  vertical-align: middle;
  display: inline-block;
  float: right;
  width: 70%;
  height: auto;
  background: #fafafa;
  padding-left: 50px;
  padding-top: 20px;
  border-left: 1px solid #000000;
}

.group_right_div {
  display: inline-block;
  margin-top: 100px
}

.group_absorbs_dots {
  width: 20px;
  height: 20px;
  background: #fafafa;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 12507;
  opacity: 0.8;
  transition: all 0.2s;
}

.dos1 {
  position: absolute;
  /*top: 250px;*/
  top: calc(100% / 6 * 1);
  right: -10px;
}

.dos2 {
  position: absolute;
  top: calc(100% / 6 * 2);
  right: -10px;
}

.dos3 {
  position: absolute;
  top: calc(100% / 6 * 3);
  right: -10px;
}

.dos4 {
  position: absolute;
  top: calc(100% / 6 * 4);
  right: -10px;
}

.dos5 {
  position: absolute;
  top: calc(100% / 6 * 5);
  right: -10px;
}


.group_right_title {
  height: 100px;
  font-size: 60px;
  color: black;
  font-family: RuiYunSongTi, serif;
  /*font-weight: 600;*/
}

.group_right_secondTitle {
  font-size: 30px;
  color: black;
  font-family: RuiYunSongTi, serif;
  /*font-weight: 600;*/
  text-align: left;
}

.group_right_group {
  text-align: left;
  justify-content: center;
  padding-left: 150px;
}

.group_right_group_div {
  display: inline-block;
  vertical-align: middle;
  height: 200px;
}

.group_right_group_div_img {
  width: 150px;
  height: 150px;
}

.group_right_group_div_div {
  display: inline-block;
  height: 150px;
  margin-left: 30px;
}

.group_right_group_div_div_title {
  color: #1E1E1E;
  font-size: 20px;
  font-weight: 600;
  vertical-align: middle;
  margin-bottom: 10px;
}
.magTop80 {
  margin-top: 80px;
}

.group_right_group_div_div_secondTitle {
  color: #1E1E1E;
  font-size: 20px;
  vertical-align: middle;
  margin-bottom: 10px;
  padding-bottom: 15px;
  text-indent: 2em;
  font-weight: 300;
  line-height: 50px;
}

.group_right_group_div_div_secondTitle div:first-child {
  margin-top: 30px;
}

.group_right_group_div_div_span {
  font-size: 14px;
  color: #717171
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
