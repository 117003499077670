<template>
  <div>
    <div class="container">

      <div class="row">

        <div class="col-md-8 col-md-offset-2">

          <div class="title-content">

            <h2 class="section-title">咨询</h2>

            <p>此处需要完善文字.</p>

          </div>

        </div>

      </div> <!-- /.row -->

    </div> <!-- /.container -->

    <section id="articles">

      <!-- Section title -->
      <h3 class="sr-only">Articles</h3>


      <div class="container">

        <div class="row">

          <div class="col-sm-6">

            <article class="blog-entry first-row-entry">

              <figure>

                <img src="http://www2.jmtcsoft.com/img/2022-10-9/blog_entry_01.jpg" alt="">

                <figcaption class="sr-only">Blog entry 01 image</figcaption>

              </figure>

              <div class="blog-entry-inner">

                <h4><a href="#">了解数字化系统</a></h4>

                <p class="author-date"><span class="date">2022年10月1日</span> by <span
                    class="author"><a href="#">王鑫</a></span></p>

                <p class="entry-content">此处需要完善文字</p>

                <a href="#" class="read-more pull-right">阅读详情...</a>

              </div> <!-- /.blog-entry-inner -->

            </article> <!-- /.blog-entry -->

          </div>

          <div class="col-sm-6">

            <article class="blog-entry first-row-entry">

              <figure>

                <img src="http://www2.jmtcsoft.com/img/2022-10-9/blog_entry_02.jpg" alt="Blog entry 02 image">

                <figcaption class="sr-only">Blog entry 02 image</figcaption>

              </figure>

              <div class="blog-entry-inner">

                <h4><a href="#">基础档案设置</a></h4>

                <p class="author-date"><span class="date">2022年10月2日</span> by <span
                    class="author"><a href="#">王鑫</a></span></p>

                <p class="entry-content">此处需要完善文字</p>

                <a href="#" class="read-more pull-right">阅读详情...</a>

              </div> <!-- /.blog-entry-inner -->

            </article> <!-- /.blog-entry -->

          </div>

        </div> <!-- /.row -->

        <div class="row">

          <div class="col-sm-6 col-md-4">

            <article class="blog-entry second-row-entry">

              <figure>

                <img src="http://www2.jmtcsoft.com/img/2022-10-9/blog_entry_03.jpg" alt="Blog entry 03 image">

                <figcaption class="sr-only">Blog entry 03 image</figcaption>

              </figure>

              <div class="blog-entry-inner">

                <h4><a href="#">关于茶文化的讲解</a></h4>

                <p class="author-date"><span class="date">2022年10月5日</span> by <span
                    class="author"><a href="#">王鑫</a></span></p>

                <p class="entry-content">此处需要完善文字</p>

                <a href="#" class="read-more pull-right">阅读详情...</a>

              </div> <!-- /.blog-entry-inner -->

            </article> <!-- /.blog-entry -->

          </div>

          <div class="col-sm-6 col-md-4">

            <article class="blog-entry second-row-entry">

              <figure>

                <img src="http://www2.jmtcsoft.com/img/2022-10-9/blog_entry_04.jpg" alt="Blog entry 04 image">

                <figcaption class="sr-only">Blog entry 04 image</figcaption>

              </figure>

              <div class="blog-entry-inner">

                <h4><a href="#">金米的介绍</a></h4>

                <p class="author-date"><span class="date">2022年10月7日</span> by <span
                    class="author"><a href="#">王鑫</a></span></p>

                <p class="entry-content">此处需要完善文字</p>

                <a href="#" class="read-more pull-right">阅读详情...</a>

              </div> <!-- /.blog-entry-inner -->

            </article> <!-- /.blog-entry -->

          </div>

          <div class="col-sm-6 col-md-4">

            <article class="blog-entry second-row-entry">

              <figure>

                <img src="http://www2.jmtcsoft.com/img/2022-10-9/blog_entry_05.jpg" alt="Blog entry 05 image">

                <figcaption class="sr-only">Blog entry 05 image</figcaption>

              </figure>

              <div class="blog-entry-inner">

                <h4><a href="#">茶艺人小程序使用教程</a></h4>

                <p class="author-date"><span class="date">2022年10月15日</span> by <span
                    class="author"><a href="#">王鑫</a></span></p>

                <p class="entry-content">此处需要完善文字</p>

                <a href="#" class="read-more pull-right">阅读详情...</a>

              </div> <!-- /.blog-entry-inner -->

            </article> <!-- /.blog-entry -->

          </div>

        </div> <!-- /.row -->

        <a href="#" class="btn btn-default yellow-white">显示 更多</a>

      </div> <!-- /.container -->

    </section> <!-- /#articles -->

    <div class="newsletter">

      <div class="container">

        <div class="row">

          <div class="col-md-6 col-md-offset-3">

            <h3>在这里可以向我们投递邮件</h3>

            <form class="newsletter-form">

              <div class="row">

                <div class="col-sm-8">

                  <label class="sr-only" for="Email">Email</label>
                  <input type="email" id="Email" placeholder="您的 邮箱">

                </div>

                <div class="col-sm-4">

                  <button type="submit" class="btn btn-default white-blue">发送</button>

                </div>

              </div> <!-- /.row -->

            </form> <!-- /.newsletter-form -->

          </div>

        </div> <!-- /.row -->

      </div> <!-- /.container -->

    </div> <!-- /.newsletter -->

  </div>
</template>

<script>

export default {
  props: {
    height:{
      type:Number,
      default:0
    }
  },
  name: "HomeMainBlog",
  data() {
    return {}
  },
  created() {

  }
}
</script>

<style scoped>

</style>
