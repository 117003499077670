<template>
  <div>
    <header id="header" style="position: relative">

      <nav id="main-navigation" class="navbar navbar-default navbar-fixed-top"
           style="background: #fafafa;border-bottom: none">

        <div v-show="isShowBack"
             style="position: fixed;top: 25px;left: 50px;width: 80px;height: 80px;border-radius: 50%;color: black;cursor: pointer;font-size: 25px"
             @click="goToBack">
          返回
        </div>

        <div v-show="isShowBack"
             style="position: fixed;top: 25px;right: 50px;width: 80px;height: 80px;border-radius: 50%;color: black;cursor: pointer;font-size: 25px"
             @click="goFull">
          <img v-show="!isFull" src="../img/全局放大_full-screen.png" alt="" width="30"
               @click="isFull = true">
          <img v-show="isFull" src="../img/全局缩小_off-screen.png" alt="" width="30"
               @click="isFull = false">
        </div>
        <div class="container" style="width: 100%;">
          <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-navbar"
                    aria-expanded="false" @click="isShowMenu = !isShowMenu">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="sr-only">Toggle navigation</span>
            </button>
            <a id="brand-mobile" class="navbar-brand navbar-brand-center smoothScroll" href="#home">
              <img alt="Molly" src="../img/2022Logo.svg">
            </a>
          </div>


          <div class="collapse navbar-collapse" :class="{'disBlock':isShowMenu}" id="main-navbar">
            <ul class="nav navbar-nav" v-if="!isChildPage">
<!--              <li v-show="!isShowBack" @click="closeMenu">-->
<!--                <a class="smoothScroll" href="#home" v-smooth-scroll>首页</a>-->
<!--              </li>-->
              <li v-show="!isShowBack" @click="closeMenu">
                <a class="smoothScroll" href="#chanpinyufuwu"
                   v-smooth-scroll="{offset:-200}">产品与服务</a>
              </li>
              <li v-show="!isShowBack" @click="closeMenu">
                <a class="smoothScroll" href="#solution" v-smooth-scroll="{offset:-80}">版本与功能</a>
              </li>
              <li v-show="!isShowBack" @click="closeMenu">
                <a class="smoothScroll" href="#partner"
                   v-smooth-scroll="{offset:-100}">合作与案例</a>
              </li>

              <li id="brand-desktop" @click="closeMenu">
                <a class="navbar-brand smoothScroll" href="/">
                  <img alt="Molly" src="../img/2022Logo.svg">
                </a>
              </li>

              <li v-show="!isShowBack" @click="closeMenu">
                <a class="smoothScroll" href="#CompanyInfo" v-smooth-scroll="{offset:-80}">了解我们</a>
              </li>
              <li v-show="!isShowBack" @click="closeMenu">
                <a class="smoothScroll" href="#download" v-smooth-scroll="{offset:-20}">下载中心</a>
              </li>
              <li v-if="screenWidth >= 800" v-show="!isShowBack" @click="toContact"  style="margin-right: 25px">
                <a class="smoothScroll" href="#contact"  v-smooth-scroll>联系我们</a>
              </li>
              <li v-if="screenWidth < 800" v-show="!isShowBack" @click="toContact">
                <a class="smoothScroll" href="#contact"  v-smooth-scroll>联系我们</a>
              </li>
<!--              <li v-show="!isShowBack" @click="toHelp">-->
<!--                <a>使用手册</a>-->
<!--              </li>-->
            </ul>

            <ul class="nav navbar-nav" v-else>
              <li v-show="!isShowBack" @click="closeMenu">
                <a class="smoothScroll" href="/#home">首页</a>
              </li>
              <li v-show="!isShowBack" @click="closeMenu">
                <a class="smoothScroll" href="/#services">产品与服务</a>
              </li>
              <li v-show="!isShowBack" @click="closeMenu">
                <a class="smoothScroll" href="/#partner">战略合作</a>
              </li>
              <li id="brand-desktop" @click="closeMenu">
                <a class="navbar-brand smoothScroll" href="/">
                  <img alt="Molly" src="../img/2022Logo.svg">
                </a>
              </li>
              <li v-show="!isShowBack" @click="closeMenu">
                <a class="smoothScroll" href="/#solution">版本介绍</a>
              </li>
              <li v-show="!isShowBack" @click="closeMenu">
                <a class="smoothScroll" href="/#download">下载中心</a>
              </li>
              <li v-show="!isShowBack" @click="this.dialogTableVisible = !this.dialogTableVisible">
                <a class="smoothScroll" href="/#contact">注册</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <el-dialog class="contactDialog" v-model="dialogTableVisible" width="80%" title="联系我们"
                 style="max-width: 800px;min-width: 200px">
        <ContactUsTabs @closeDialog="closeDialog"></ContactUsTabs>
      </el-dialog>

      <el-dialog class="contactDialog" v-model="dialogTableVisible2" top="60px" width="100%" title="联系我们">
        <contact-us-tabs-for-phone @closeDialog="closeDialog2"></contact-us-tabs-for-phone>
      </el-dialog>

    </header>
  </div>

</template>

<script>
import screenfull from 'screenfull'
import ContactUsTabs from "@/components/ContactUs/ContactUsTabs";
import ContactUsTabsForPhone from "@/components/ContactUs/ContactUsTabsForPhone";

export default {
  components: {
    screenfull,
    ContactUsTabs,
    ContactUsTabsForPhone
  },
  props: {
    isShowBack: {
      type: Boolean,
      default: false,
    },
    isChildPage: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      screenWidth: 0,
      isFull: false,
      isShowMenu: false,
      dialogTableVisible: false,
      dialogTableVisible2: false,
      tabsName: "system",
    }
  },
  name: "HomeHeader",
  mounted() {
    window.addEventListener('resize', this.screenType);
    this.screenWidth = document.body.offsetWidth;
  },
  unmounted() {
    window.removeEventListener('resize', this.screenType)
  },
  methods: {
    toHelp(){
      window.open("https://www.wolai.com/8iMQ67ZwdF3T97kytYZoYy");
    },
    toContact() {
      if (this.screenWidth < 800) {
        this.dialogTableVisible2 = true;
      } else {
        this.dialogTableVisible = true;
      }
    },
    closeDialog() {
      this.dialogTableVisible = false;
    },
    closeDialog2() {
      this.dialogTableVisible2 = false;
    },
    goToBack() {
      this.$router.push({path: '/'})
    },
    goFull() {
      screenfull.toggle();
    },
    goNoFull() {
      screenfull.toggle();
    },
    closeMenu() {
      this.isShowMenu = false;
    }
  }
}
</script>

<style scoped>


.disBlock {
  display: block;
}
</style>
