<template>
  <div v-for="(item,index) in list" :key="index">
    <NewService :title="item.title" :second="item.second" :btn-text="item.btnText" :img="item.img" :text="item.text"
                :url="item.url"
                :index="index" :reverse="item.isRevers" :showDetail="item.showDetail" @click_Func="clickFunc"></NewService>
  </div>

  <el-dialog class="contactDialog" v-model="dialogTableVisible" width="80%" title="联系我们"
             style="max-width: 800px;min-width: 200px">
    <ContactUsTabs @closeDialog="closeDialog"></ContactUsTabs>
  </el-dialog>

  <el-dialog class="contactDialog" v-model="dialogTableVisible2" top="60px" width="100%" title="联系我们">
    <contact-us-tabs-for-phone @closeDialog="closeDialog2"></contact-us-tabs-for-phone>
  </el-dialog>
</template>

<script>
import NewService from "@/components/PageTools/NewService";
import ContactUsTabs from "@/components/ContactUs/ContactUsTabs";
import ContactUsTabsForPhone from "@/components/ContactUs/ContactUsTabsForPhone";


export default {
  components: {
    NewService,
    ContactUsTabs,
    ContactUsTabsForPhone
  },
  props: {},
  name: "HomeMainServices",
  data() {
    return {
      dialogTableVisible: false,
      dialogTableVisible2: false,
      screenWidth: 0,
      list: [
        {
          title: "管理系统",
          second: "茶楼茶店线上线下一体化系统",
          btnText: "立即联系",
          img: "数字化系统.png",
          text: "集前台营业、库存管理、会员管理、员工管理、采购管理、批发管理、财务管理、商城管理八大系统，线上、线下一体化管理，支持单店或总部分店连锁架构。",
          // url: "/system",
          url:"https://mp.weixin.qq.com/s/hiveUOHJa9v4uCTiF1Hcpg",
          isRevers: false,
          showDetail:true,
        }, {
          title: "人才服务",
          second: "茶馆招聘 茶艺师培训",
          btnText: "立即联系",
          img: "茶艺师.png",
          text: "提供茶艺师、店长、管家等岗位的培训、认证。发布茶艺师、琴师、插花师、店长等岗位人才的招聘信息，可以查看人才库，除了可以看到应聘者的简历等基本个人信息，还可以查看应聘者的茶圈动态，以及“茶艺人”认证级别，能更全面的了解应聘者。",
          url: "/rencaipeixunIntro",
          isRevers: true,
          showDetail:false,
        }, {
          title: "托管运营",
          second: "茶馆托管 品牌运营",
          btnText: "立即联系",
          img: "托管运营.png",
          text: "针对茶馆茶楼茶店存在的运营难点提供系统化解决方案，提供品牌策划、产品设计、空间设计、营销推广、制度落地、团队搭建服务，能极大提高茶馆运营效率，降本增效，减少试错成本。",
          url: "/tuoguanyunyingIntro",
          isRevers: false,
          showDetail:false,
        }, {
          title: "茶馆集采",
          second: "对接优质供应链 降低采购成本",
          btnText: "立即联系",
          img: "茶馆采购.png",
          text: "茶馆采购主要包括茶叶采购、泡茶水、茶事家具、定制茶礼盒、茶具/茶服采购、等茶周边内容。用优质供应链组织货源，降低采购成本。解决中小规模茶馆没有专业的市场采购人员、单次采购量少、品控不稳定，并且部分茶品不能持续供应的问题。",
          url: "/chaguanjicaiIntro",
          isRevers: true,
          showDetail:false,
        }
      ]
    }
  },
  methods: {
    clickFunc(index) {
      if (this.screenWidth<800) {
        this.dialogTableVisible2 = true;
      }else {
        this.dialogTableVisible = true;
      }
    },
    closeDialog() {
      this.dialogTableVisible = false;
    },
    closeDialog2() {
      this.dialogTableVisible2 = false;
    }
  },
  mounted() {
    window.addEventListener('resize', this.screenType);
    this.screenWidth = document.body.offsetWidth;
  }
}
</script>

<style scoped>

</style>
