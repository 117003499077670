<template>

  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <div class="title-content">
          <h2 class="section-title">更多品牌的共同选择</h2>
        </div>
      </div>

    </div>

    <div class="flex_card">
<!--      <img src="../../assets/PartnerLogo/LogoPC.png" alt="">-->
      <!--      <div v-for="(item,index) in imgList" :key="index"-->
      <!--           style="max-width: 400px;min-width: 300px;display: flex;justify-content: center;padding: 5px;max-height: 200px;    box-shadow: 0 0 0 1px #eaeaea, 0 16px 24px rgb(0 0 0 / 2%);-->
      <!--    margin: 3px">-->
      <!--        <img class="card_item_img" :src="item.url" alt="">-->
      <!--      </div>-->
    </div>

    <div class="flex_cardByPhone">

    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 0
    }
  },
  name: "HomeMainPartner",
  data() {
    return {
      baseUrl: "../../assets/PartnerLogo/",
      imgList: [
        {
          url: require("../../assets/PartnerLogo/Partner1.png"),
        },
        {
          url: require("../../assets/PartnerLogo/Partner2.png"),
        },
        {
          url: require("../../assets/PartnerLogo/Partner3.png"),
        },
        {
          url: require("../../assets/PartnerLogo/Partner4.png"),
        },
        {
          url: require("../../assets/PartnerLogo/Partner6.png"),
        },
        {
          url: require("../../assets/PartnerLogo/Partner7.png"),
        },
        {
          url: require("../../assets/PartnerLogo/古乐.png"),
        },
        {
          url: require("../../assets/PartnerLogo/小罐.png"),
        },
        {
          url: require("../../assets/PartnerLogo/嘉石木.png"),
        },
        {
          url: require("../../assets/PartnerLogo/阅叶.png"),
        },
        {
          url: require("../../assets/PartnerLogo/华祥苑.png"),
        },
        {
          url: require("../../assets/PartnerLogo/品臻茶舍.png"),
        },
        {
          url: require("../../assets/PartnerLogo/嘉悦.png"),
        },
        {
          url: require("../../assets/PartnerLogo/涵山.png"),
        },
        {
          url: require("../../assets/PartnerLogo/澜沧.png"),
        },
        {
          url: require("../../assets/PartnerLogo/琳泉阁.png"),
        },
        {
          url: require("../../assets/PartnerLogo/福.png"),
        },
        {
          url: require("../../assets/PartnerLogo/绿雪芽.png"),
        },
        {
          url: require("../../assets/PartnerLogo/观止.png"),
        },
        {
          url: require("../../assets/PartnerLogo/露雨轩.png"),
        },
      ]
    }
  }
}
</script>

<style scoped>

.flex_card {
  margin: 0 auto;
  width: 80%;
  height: auto;
  /*border: 1px solid rgb(0, 0, 0);*/
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;
}

.card_top {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  transition: all 0.5s;
}

.card {
  width: auto;
  max-width: 200px;
  height: auto;
  border-radius: 8px;
  /*box-shadow: 0 4px 20px rgb(0 0 0 / 7%);*/
  /*background: #ffffff;*/
  display: flex;
  /*margin: 5px;*/
  transition: all 0.5s;
}

.item {
  width: 100%;
  padding: 10px 0;
}

.card_item_div {
  justify-content: center;
  align-items: center
}

.card_item_img {
  width: 100%;
  height: 100%;
  display: inline-block;
}

/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {
  .flex_card {
    display: none;
  }

  .flex_cardByPhone {
    display: block;
    width: 100%;
    min-height: 600px;
    height: auto;
    background: url("../../assets/PartnerLogo/LogoPhone.png") no-repeat center center;
    background-size: contain;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 768px) and (max-width: 992px) {
  .card {
    width: auto;
    max-width: 200px;
    height: auto;
    /*border-radius: 8px;*/
    /*box-shadow: 0 4px 20px rgb(0 0 0 / 7%);*/
    /*background: #ffffff;*/
    display: flex;
    margin: 5px;
    transition: all 0.5s;
  }

  .card :hover {
    transform: scale(1.2);
    transition: all 0.5s;
  }

  .flex_card {
    display: block;
    width: 100%;
    min-height: 600px;
    height: auto;
    background: url("../../assets/PartnerLogo/LogoPC.png") no-repeat center center;
    background-size: contain;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 992px) {

  .card {
    width: auto;
    max-width: 200px;
    height: auto;
    /*border-radius: 8px;*/
    /*box-shadow: 0 4px 20px rgb(0 0 0 / 7%);*/
    /*background: #ffffff;*/
    display: flex;
    margin: 5px;
    transition: all 0.5s;
  }

  .card :hover {
    transform: scale(1.2);
    transition: all 0.5s;
  }

  .flex_card {
    display: block;
    width: 100%;
    min-height: 600px;
    height: auto;
    background: url("../../assets/PartnerLogo/LogoPC.png") no-repeat center center;
    background-size: contain;
  }
}

</style>
