<template>
  <!-- Section title -->

  <div id="carousel-hero" class="carousel slide" data-ride="carousel" style="width: 100%;height: 100%">
    <!-- Wrapper for slides -->
    <el-carousel indicator-position="outside" :interval="5000">
      <el-carousel-item v-for="item in 3" :key="item" style="height: 100% !important;">
        <div class="carousel_item_div1" v-show="item === 1">
        </div>
        <div class="carousel_item_div2" v-show="item === 2">
        </div>
        <div class="carousel_item_div3" v-show="item === 3">
        </div>
      </el-carousel-item>
    </el-carousel>
  </div> <!-- /.carousel -->

</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 950
    },
  },
  name: "HomeMainHome",
  data() {
    return {
      item: ""
    }
  }
}
</script>

<style>
.el-carousel__item h3 {
  display: flex;
  color: #475669;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

/*noinspection CssUnusedSymbol*/
.el-carousel__item:nth-child(2n) {

}

/*noinspection CssUnusedSymbol*/
.el-carousel__item:nth-child(2n + 1) {

}


/* 小屏幕手机端 */
@media (min-width: 0px) and (max-width: 768px) {

  .el-carousel__container {
    height: 300px !important;
  }

  .carousel_item_div1 {
    height: 100%;
    background: url('../../assets/Popup/轮播图1.jpg') center center no-repeat;
    background-size: cover;
  }

  .carousel_item_div2 {
    height: 100%;
    background: url("../../assets/Popup/轮播图2.png") center center no-repeat;
    background-size: cover;
  }

  .carousel_item_div3 {
    height: 100%;
    background: url("../../assets/Popup/轮播图3.png") center center no-repeat;
    background-size: cover;
  }
}

/* 中等屏幕（桌面显示器，大于等于 992px） */
@media (min-width: 768px) and (max-width: 992px) {
  .carousel_item_div1 {
    height: 100%;
    background: url('../../assets/Popup/轮播图1.jpg') center center no-repeat;
    background-size: cover;
  }

  .carousel_item_div2 {
    height: 100%;
    background: url("../../assets/Popup/轮播图2.png") center center no-repeat;
    background-size: cover;
  }

  .carousel_item_div3 {
    height: 100%;
    background: url("../../assets/Popup/轮播图3.png") center center no-repeat;
    background-size: cover;
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (min-width: 992px) {

  .el-carousel__container {
    height: 700px !important;
  }

  .carousel_item_div1 {
    height: 100%;
    background: url('../../assets/Popup/轮播图1.jpg') center center no-repeat;
    background-size: cover;
  }

  .carousel_item_div2 {
    height: 100%;
    background: url("../../assets/Popup/轮播图2.png") center center no-repeat;
    background-size: cover;
  }

  .carousel_item_div3 {
    height: 100%;
    background: url("../../assets/Popup/轮播图3.png") center center no-repeat;
    background-size: cover;
  }
}

</style>
